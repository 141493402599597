@charset "UTF-8";
/*
  reset
--------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

input[type="button"], input[type="submit"] {
  -webkit-appearance: none; }

/*
  base
--------------------------------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

img {
  border-style: none;
  display: block;
  vertical-align: bottom; }

a,
a img,
button {
  transition: .4s; }

a img:hover {
  opacity: .7; }

a:link {
  color: #333;
  text-decoration: underline; }

a:visited {
  color: #333; }

a:hover {
  color: #333;
  text-decoration: none; }

a:active {
  color: #333; }

.center {
  text-align: center; }

.t_left {
  text-align: left; }

.t_right {
  text-align: right; }

.mb_10 {
  margin-bottom: 10px; }

.mb_20 {
  margin-bottom: 20px; }

.mb_30 {
  margin-bottom: 30px; }

.mb_40 {
  margin-bottom: 40px; }

.mb_50 {
  margin-bottom: 50px; }

.mb_60 {
  margin-bottom: 60px; }

.mb_70 {
  margin-bottom: 70px; }

.mb_80 {
  margin-bottom: 80px; }

.mb_90 {
  margin-bottom: 90px; }

.mb_100 {
  margin-bottom: 100px; }

.mt_10 {
  margin-top: 10px; }

.mt_20 {
  margin-top: 20px; }

.mt_30 {
  margin-top: 30px; }

.mt_40 {
  margin-top: 40px; }

.mt_50 {
  margin-top: 50px; }

.mt_60 {
  margin-top: 60px; }

.mt_70 {
  margin-top: 70px; }

.mt_80 {
  margin-top: 80px; }

.mt_90 {
  margin-top: 90px; }

.mt_100 {
  margin-top: 100px; }

.pb_10 {
  padding-bottom: 10px; }

.pb_20 {
  padding-bottom: 20px; }

.pb_30 {
  padding-bottom: 30px; }

.pb_40 {
  padding-bottom: 40px; }

.pb_50 {
  padding-bottom: 50px; }

.pb_60 {
  padding-bottom: 60px; }

.pb_70 {
  padding-bottom: 70px; }

.pb_80 {
  padding-bottom: 80px; }

.pb_90 {
  padding-bottom: 90px; }

.pb_100 {
  padding-bottom: 100px; }

.pt_10 {
  padding-top: 10px; }

.pt_20 {
  padding-top: 20px; }

.pt_30 {
  padding-top: 30px; }

.pt_40 {
  padding-top: 40px; }

.pt_50 {
  padding-top: 50px; }

.pt_60 {
  padding-top: 60px; }

.pt_70 {
  padding-top: 70px; }

.pt_80 {
  padding-top: 80px; }

.pt_90 {
  padding-top: 90px; }

.pt_100 {
  padding-top: 100px; }

@media (min-width: 768px) {
  /*
 PC common
---------------------------------------- */
  .sp-on {
    display: none; }
  .pc-on {
    display: block; }
  body {
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "游ゴシック", "Yu Gothic", YuGothic, Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 500;
    color: #333;
    width: 100%;
    margin: 0 auto; }
    body .inner {
      margin: 0 auto; }
    body .inline {
      display: flex;
      flex-wrap: wrap; }
    body header {
      background-color: #fff;
      position: fixed;
      height: 94px;
      width: 100%;
      border-top: 4px solid #b3b3b3;
      z-index: 50; }
      body header .header_cont {
        width: 100%; }
      body header div,
      body header nav {
        font-size: 0;
        display: inline-block; }
      body header .title_hldr {
        margin-top: 30px;
        margin-left: 30px; }
        body header .title_hldr img {
          width: 260px; }
      body header .main_menu {
        float: right; }
        body header .main_menu .access {
          font-size: 12px;
          width: 100%;
          position: relative;
          right: 0; }
          body header .main_menu .access .tel {
            font-size: 22px;
            vertical-align: middle;
            font-family: Lato;
            font-weight: 400;
            margin-right: 10px; }
            body header .main_menu .access .tel img {
              max-width: 100%;
              margin-right: 5px;
              display: inline-block;
              vertical-align: middle; }
          body header .main_menu .access p {
            display: inline-block; }
        body header .main_menu nav {
          width: 100%;
          display: block; }
          body header .main_menu nav ul li {
            margin: 10px 0;
            border-left: 1px solid #000; }
            body header .main_menu nav ul li a {
              font-size: 14px;
              padding: 10px 30px;
              text-decoration: none; }
      body header .cv_hldr {
        width: 90px;
        height: 90px;
        background-color: #638a9d;
        text-align: center;
        font-size: 13px;
        vertical-align: top;
        float: right; }
        body header .cv_hldr a {
          color: #fff;
          text-decoration: none; }
          body header .cv_hldr a img {
            max-width: 100%;
            margin: 10px auto; }
    body header:after {
      content: ".";
      line-height: 0;
      display: block;
      overflow: hidden;
      position: absolute;
      top: -4px;
      right: 0;
      width: calc(100%/3);
      border-top: 4px solid #638a9d; }
    body header:before {
      content: ".";
      line-height: 0;
      display: block;
      overflow: hidden;
      position: absolute;
      top: -4px;
      width: calc(100%/3);
      border-top: 4px solid #09469b; }
    body .pankuzu {
      padding: 14px 0;
      border-bottom: 1px solid #ccc; }
      body .pankuzu ul {
        font-size: 0;
        width: 78.125%;
        margin: auto; }
        body .pankuzu ul li {
          display: inline-block;
          font-size: 12px; }
          body .pankuzu ul li a {
            text-decoration: none; }
        body .pankuzu ul li::after {
          content: ">";
          margin: 0 5px; }
        body .pankuzu ul li:last-child::after {
          content: none; }
    body section .btn01,
    body section .btn02,
    body section .btn03 {
      text-decoration: none; }
      body section .btn01 p,
      body section .btn02 p,
      body section .btn03 p {
        width: 260px;
        height: 42px;
        border-radius: 21px;
        background-color: #fff;
        text-align: center;
        font-size: 15px;
        line-height: 38px; }
    body section .btn01 p {
      border: solid 2px #638a9d;
      color: #638a9d; }
    body section .btn02 p {
      border: solid 2px #002b69;
      color: #002b69; }
    body section .btn03 p {
      border: solid 2px #fff;
      color: #fff;
      background-color: inherit; }
    body section .img-area img {
      width: 100%; }
    body .wrapper {
      width: 78.125%;
      margin: 0 auto 175px;
      overflow: hidden; }
      body .wrapper section {
        width: 71.111111%;
        margin-top: 60px;
        float: left; }
        body .wrapper section h2 {
          box-sizing: border-box;
          font-size: 1.71em;
          border-top: 3px solid #ccc;
          border-bottom: 1px solid #ccc;
          padding: 30px 0;
          line-height: 1.2;
          margin-bottom: 24px; }
        body .wrapper section h3 {
          box-sizing: border-box;
          font-size: 1.57em;
          padding: 28px;
          line-height: 1;
          background-color: #f2f2f2;
          margin: 58px 0 30px;
          border-radius: 4px; }
    body footer {
      border-top: 1px solid #ccc; }
      body footer .form-area {
        padding: 35px 0; }
        body footer .form-area .inner {
          font-size: 0;
          width: 1124px;
          background-color: #fff;
          margin: auto; }
          body footer .form-area .inner .title_hldr {
            width: 240px;
            padding-right: 30px;
            margin-right: 30px;
            height: 60px;
            display: inline-block;
            border-right: 1px solid #ccc; }
            body footer .form-area .inner .title_hldr img {
              width: 100%;
              margin-top: 16px; }
          body footer .form-area .inner .cont_01 {
            width: 850px;
            display: inline-block;
            font-size: 0;
            height: 60px;
            padding-top: 8px;
            vertical-align: top; }
            body footer .form-area .inner .cont_01 .access {
              display: inline-block;
              text-align: center;
              padding-right: 30px; }
              body footer .form-area .inner .cont_01 .access p {
                font-size: 14px;
                line-height: 1.47; }
                body footer .form-area .inner .cont_01 .access p .color_txt {
                  font-size: 1.21em;
                  color: #09469b; }
            body footer .form-area .inner .cont_01 .tel {
              display: inline-block;
              font-size: 44px;
              vertical-align: middle;
              font-family: Lato;
              font-weight: 400;
              color: #002b69;
              padding-bottom: 26px;
              line-height: 0; }
              body footer .form-area .inner .cont_01 .tel img {
                display: inline-block;
                vertical-align: middle;
                margin: 0 7px 6px 0; }
            body footer .form-area .inner .cont_01 .btn_form {
              display: inline-block;
              float: right;
              margin-left: 15px; }
              body footer .form-area .inner .cont_01 .btn_form a {
                width: 250px;
                height: 42px;
                text-align: center;
                display: block;
                background-color: #09469b;
                color: #fff;
                text-decoration: none;
                border-radius: 21px;
                font-size: 16px;
                line-height: 40px; }
      body footer .link_area {
        background-color: #ccc; }
        body footer .link_area .inner {
          width: 1124px;
          font-size: 0;
          padding: 50px 0 30px; }
          body footer .link_area .inner p.copy {
            color: #638a9d;
            font-size: 13px;
            text-align: right;
            padding-top: 34px; }
          body footer .link_area .inner .cont_left {
            display: inline-block;
            width: 35%;
            padding-right: 5%;
            vertical-align: top;
            font-size: 14px; }
            body footer .link_area .inner .cont_left p:nth-child(1) {
              font-weight: bold; }
            body footer .link_area .inner .cont_left .img_hldr {
              font-size: 0;
              margin-top: 20px; }
              body footer .link_area .inner .cont_left .img_hldr img {
                margin-bottom: 8px; }
          body footer .link_area .inner .cont_right {
            width: 62%;
            display: inline-block; }
            body footer .link_area .inner .cont_right ul {
              display: inline-block;
              vertical-align: top;
              margin-right: 60px; }
              body footer .link_area .inner .cont_right ul li a {
                font-size: 13px;
                text-decoration: none; }
            body footer .link_area .inner .cont_right ul:last-child {
              margin-right: 0; }
    body .side-nav {
      width: 25.333333%;
      float: right;
      margin-top: 58px; }
      body .side-nav ul li {
        border-bottom: 1px solid #fff; }
        body .side-nav ul li a {
          display: block;
          font-size: 14px;
          background-color: #f2f2f2;
          text-decoration: none;
          padding: 24px 20px;
          color: #638a9d; }
        body .side-nav ul li a::after {
          content: ">";
          float: right; }
      body .side-nav ul li:last-child {
        border-bottom: none; }
  /*
index
---------------------------------------- */
  body.home .main .mv {
    position: relative; }
    body.home .main .mv .img-area {
      padding-top: 4px; }
      body.home .main .mv .img-area #bgvid {
        width: 100%;
        vertical-align: bottom; }
      body.home .main .mv .img-area img {
        width: 100%; }
    body.home .main .mv .text-area {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 650px;
      height: 250px;
      text-align: center;
      background: url(../img/top_bg.png);
      background-repeat: no-repeat;
      background-position: center; }
      body.home .main .mv .text-area p {
        color: #fff; }
      body.home .main .mv .text-area p:nth-of-type(1) {
        margin: 15px 0;
        font-size: 38px; }
      body.home .main .mv .text-area p:nth-of-type(2) {
        font-size: 18px;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        margin: 0 auto;
        padding: 5px 0;
        width: 60%; }
      body.home .main .mv .text-area p:nth-of-type(3) {
        margin-top: 30px;
        font-size: 16px; }
  body.home .main section h2 {
    font-family: 'Amiri', serif;
    font-size: 42px;
    color: #b3b3b3;
    letter-spacing: 0.2em; }
  body.home .main section h3 {
    color: #638a9d;
    font-size: 20px; }
  body.home .main section .area01 {
    position: relative; }
    body.home .main section .area01 .inner {
      width: 1124px; }
      body.home .main section .area01 .inner .text-area {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 350px;
        margin: auto; }
        body.home .main section .area01 .inner .text-area p:nth-child(3) {
          margin: 30px 0; }
  body.home .main section .area02 {
    text-align: center;
    margin: 70px 0; }
    body.home .main section .area02 .inner {
      width: 1124px;
      justify-content: space-between;
      margin-top: 50px; }
      body.home .main section .area02 .inner .left-box,
      body.home .main section .area02 .inner .right-box {
        width: calc(95%/2); }
        body.home .main section .area02 .inner .left-box .text-area h4,
        body.home .main section .area02 .inner .right-box .text-area h4 {
          font-size: 18px;
          color: #002b69;
          margin-bottom: 15px; }
        body.home .main section .area02 .inner .left-box .text-area p,
        body.home .main section .area02 .inner .right-box .text-area p {
          text-align: left;
          width: 90%;
          margin: 0 auto; }
      body.home .main section .area02 .inner .left-box {
        position: relative; }
        body.home .main section .area02 .inner .left-box .text-area {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 130px; }
      body.home .main section .area02 .inner .right-box {
        position: relative; }
        body.home .main section .area02 .inner .right-box .text-area {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 125px; }
    body.home .main section .area02 .btn02 p {
      margin: 25px auto 0; }
  body.home .main section .area03 {
    text-align: center; }
    body.home .main section .area03 .inline .left-box,
    body.home .main section .area03 .inline .right-box {
      width: calc(100%/2); }
      body.home .main section .area03 .inline .left-box .text-area h3,
      body.home .main section .area03 .inline .right-box .text-area h3 {
        color: #fff;
        margin-bottom: 25px; }
      body.home .main section .area03 .inline .left-box .text-area p,
      body.home .main section .area03 .inline .right-box .text-area p {
        color: #fff; }
      body.home .main section .area03 .inline .left-box .text-area .btn03 p,
      body.home .main section .area03 .inline .right-box .text-area .btn03 p {
        margin: 25px auto 0; }
    body.home .main section .area03 .inline .left-box {
      position: relative; }
      body.home .main section .area03 .inline .left-box .text-area {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        height: 250px; }
    body.home .main section .area03 .inline .right-box {
      position: relative; }
      body.home .main section .area03 .inline .right-box .text-area {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        height: 250px; }
    body.home .main section .area03 .box {
      position: relative; }
      body.home .main section .area03 .box .text-area {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        height: 425px;
        width: 680px;
        background-color: #fff;
        border-radius: 10px; }
        body.home .main section .area03 .box .text-area .inline {
          justify-content: space-around;
          width: 80%;
          margin: auto; }
        body.home .main section .area03 .box .text-area h2 {
          margin-top: 66px; }
        body.home .main section .area03 .box .text-area p {
          margin: 20px 0; }
        body.home .main section .area03 .box .text-area p:nth-child(4):before {
          content: "商材一例";
          padding: 5px 10px;
          background-color: #638a9d;
          color: #fff;
          margin-right: 15px; }
    body.home .main section .area03 .left-box02 {
      background: url(../img/index04.png) no-repeat;
      background-size: cover;
      width: 100%;
      padding: 130px 0 140px; }
      body.home .main section .area03 .left-box02 .text-area h3 {
        color: #fff;
        margin-bottom: 25px; }
      body.home .main section .area03 .left-box02 .text-area p {
        color: #fff; }
      body.home .main section .area03 .left-box02 .text-area .btn03 p {
        margin: 25px auto 0; }
  /*
index
---------------------------------------- */
  body.training .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/training_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.training .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.training .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.training .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.training .main .wrapper .s1 .inner .cont01 .txt_area {
    background-color: #ebf1f4;
    padding: 33px; }
    body.training .main .wrapper .s1 .inner .cont01 .txt_area h4 {
      font-size: 1.565em;
      color: #002b69;
      line-height: 1;
      margin-bottom: 27px;
      font-weight: bold; }
  body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 {
    width: 100%;
    font-size: 0; }
    body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box {
      width: calc( 100%/2 - 12px);
      display: inline-block;
      vertical-align: top;
      margin-right: 24px; }
      body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box img {
        width: 100%; }
      body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box h5 {
        font-size: 20px;
        padding: 20px 0; }
      body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box p {
        font-size: 14px; }
    body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box:last-child {
      margin-right: 0; }
  body.training .main .wrapper .side-nav ul li:nth-child(4) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.welfare .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/welfare_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.welfare .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.welfare .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.welfare .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.welfare .main .wrapper .s1 .inner .cont01 .txt_area h4 {
    padding: 38px 0 23px;
    font-size: 1.42em;
    color: #002b69;
    line-height: 1;
    font-weight: bold; }
  body.welfare .main .wrapper .s1 .inner .cont01 .txt_area table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d4d4d4; }
    body.welfare .main .wrapper .s1 .inner .cont01 .txt_area table tr {
      border-bottom: 1px solid #d4d4d4; }
      body.welfare .main .wrapper .s1 .inner .cont01 .txt_area table tr th {
        width: 34.5%;
        background-color: #f2f2f2;
        padding: 20px 17px;
        color: #002b69; }
      body.welfare .main .wrapper .s1 .inner .cont01 .txt_area table tr td {
        padding: 20px 17px; }
  body.welfare .main .wrapper .side-nav ul li:nth-child(4) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.business .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/business_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.business .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.business .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.business .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.business .main .wrapper .s1 .inner .cont01 .b_01 {
    background-image: url(../img/business_bg01.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 259px;
    position: relative; }
    body.business .main .wrapper .s1 .inner .cont01 .b_01 .txt_area {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      right: 8%; }
  body.business .main .wrapper .s1 .inner .cont01 .b_02 {
    background-image: url(../img/business_bg02.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 259px;
    position: relative; }
    body.business .main .wrapper .s1 .inner .cont01 .b_02 .txt_area {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      left: 10%; }
  body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box:nth-of-type(3) {
    background-image: url(../img/business_bg03.png);
    margin-bottom: 0; }
  body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box {
    margin-bottom: 13px; }
    body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area {
      text-align: center;
      color: #fff;
      line-height: 1; }
      body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area p {
        font-size: 2.71em;
        padding: 0 10px 15px;
        border-bottom: 1px solid #fff; }
      body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area h4 {
        padding: 15px 0 0 0;
        font-size: 1.14em; }
  body.business .main .wrapper .s1 .inner .cont_inner {
    background-image: url(../img/business_bg04.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 483px;
    overflow: hidden;
    margin: 73px 0 0 0; }
    body.business .main .wrapper .s1 .inner .cont_inner .cont_inner_txt {
      width: 61%;
      float: right;
      padding: 57px 0 0 0; }
      body.business .main .wrapper .s1 .inner .cont_inner .cont_inner_txt h5 {
        color: #002b69;
        font-size: 1.42em;
        margin-bottom: 32px; }
      body.business .main .wrapper .s1 .inner .cont_inner .cont_inner_txt 　p {
        line-height: 1.85; }
  body.business .main .wrapper .side-nav ul li:nth-child(2) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.company .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/company_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.company .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.company .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.company .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.company .main .wrapper .s1 .inner .cont01 .txt_area {
    background-color: #ebf1f4;
    padding: 33px 30px;
    overflow: hidden;
    margin-bottom: 42px; }
    body.company .main .wrapper .s1 .inner .cont01 .txt_area h4 {
      font-size: 1.57em;
      color: #002b69;
      line-height: 1;
      font-weight: bold; }
    body.company .main .wrapper .s1 .inner .cont01 .txt_area .color_txt {
      font-size: 1.14em;
      color: #002b69;
      padding: 18px 0 33px;
      line-height: 1; }
    body.company .main .wrapper .s1 .inner .cont01 .txt_area img {
      padding-top: 22px;
      float: right; }
  body.company .main .wrapper .s1 .inner .cont02 .txt_area table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d4d4d4; }
    body.company .main .wrapper .s1 .inner .cont02 .txt_area table tr {
      border-bottom: 1px solid #d4d4d4; }
      body.company .main .wrapper .s1 .inner .cont02 .txt_area table tr th {
        width: 34.5%;
        background-color: #f2f2f2;
        padding: 20px 17px;
        color: #002b69; }
      body.company .main .wrapper .s1 .inner .cont02 .txt_area table tr td {
        padding: 20px 17px; }
  body.company .main .wrapper .s1 .inner_02 {
    margin: 115px 0 0 0; }
    body.company .main .wrapper .s1 .inner_02 .cont01 {
      font-size: 0; }
      body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box {
        vertical-align: top;
        width: calc(100%/2 - 12px);
        display: inline-block;
        margin: 0 24px 45px 0; }
        body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box h4 {
          font-size: 20px;
          color: #002b69; }
        body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box p {
          font-size: 14px;
          letter-spacing: -0.03em; }
      body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box:nth-child(even) {
        margin-right: 0; }
      body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box:nth-child(3), body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box:nth-child(4) {
        margin-bottom: 0; }
  body.company .main .wrapper .side-nav ul li:nth-child(2) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.service .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/service_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.service .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.service .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.service .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.service .main .wrapper .s1 .inner .cont01 .b_01 {
    background-image: url(../img/service_bg01.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 259px;
    position: relative; }
    body.service .main .wrapper .s1 .inner .cont01 .b_01 .txt_area {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      right: 8%; }
  body.service .main .wrapper .s1 .inner .cont01 .b_02 {
    background-image: url(../img/service_bg02.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 259px;
    position: relative; }
    body.service .main .wrapper .s1 .inner .cont01 .b_02 .txt_area {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      left: 10%; }
  body.service .main .wrapper .s1 .inner .cont01 .inner_cont_box {
    margin-bottom: 13px; }
    body.service .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area {
      text-align: center;
      color: #fff;
      line-height: 1; }
      body.service .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area p {
        font-size: 2.71em;
        padding: 0 10px 15px;
        border-bottom: 1px solid #fff; }
      body.service .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area h4 {
        padding: 15px 0 0 0;
        font-size: 1.14em; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 p:nth-child(1) {
    margin: 30px 0; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box {
    position: relative; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box .img_area img {
      width: 100%; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box .txt_area {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      height: 100px;
      text-align: center;
      width: 650px; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box .txt_area h4 {
        font-size: 1.565em;
        color: #09469b;
        line-height: 1;
        margin-bottom: 20px;
        font-weight: bold; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box:nth-of-type(1) {
    margin-bottom: 13px; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 {
    width: 100%; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 .inner_box {
      width: 100%;
      vertical-align: top;
      margin-right: 24px; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 .inner_box img {
        width: 100%; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 .inner_box p {
        margin-bottom: 25px; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 .inner_box .subtxt {
        font-size: 13px;
        margin-top: 10px;
        text-align: right; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box {
    margin-top: 30px; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box .img_area {
      width: 48%;
      margin-right: 2%;
      float: left; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box .img_area img {
        width: 100%; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box .txt_area {
      width: 48%;
      margin-left: 2%;
      float: left; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box .txt_area h5 {
        font-size: 20px;
        padding: 10px 0;
        font-weight: bold; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box:after {
    content: "";
    display: block;
    clear: both; }
  body.service .main .wrapper .side-nav ul li:nth-child(1) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.support .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/support_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.support .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.support .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.support .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.support .main .wrapper .s1 .inner .cont01, body.support .main .wrapper .s1 .inner .cont02 {
    margin-bottom: 100px; }
    body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 {
      width: 100%;
      font-size: 0; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 h4, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 h4, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 h4, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 h4 {
        font-family: 'Amiri', serif;
        font-size: 2rem;
        color: #b3b3b3;
        line-height: 1;
        margin-bottom: 10px; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 h4:after, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 h4:after, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 h4:after, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 h4:after {
        content: counter(support);
        font-size: 3rem; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box {
        width: calc( 100%/2 - 12px);
        display: inline-block;
        vertical-align: top;
        margin-right: 24px;
        margin-top: 25px;
        counter-increment: support; }
        body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box img, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box img, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box img, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box img {
          width: 100%; }
        body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box h5, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box h5, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box h5, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box h5 {
          font-size: 20px;
          padding: 20px 0 15px;
          line-height: 1.2;
          font-weight: bold; }
        body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box p, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box p, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box p, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box p {
          font-size: 14px; }
        body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box h6, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box h6, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box h6, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box h6 {
          color: #002b69;
          font-size: 14px;
          font-weight: bold;
          text-align: right; }
          body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box h6 span, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box h6 span, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box h6 span, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box h6 span {
            font-size: 20px;
            margin-left: 10px; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n), body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box:nth-child(2n), body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n), body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box:nth-child(2n) {
        margin-right: 0; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(n+3), body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box:nth-child(n+3), body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box:nth-child(n+3), body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box:nth-child(n+3) {
        margin-top: 50px; }
    body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box img, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box img {
      width: auto;
      max-width: 100%;
      margin: 0 auto; }
    body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box .txt-area, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box .txt-area {
      border-radius: 10px;
      border: solid 1px #cbcbcb;
      padding: 0 20px 20px; }
  body.support .main .wrapper .s1 .inner .cont02 {
    margin-top: 100px; }
  body.support .main .wrapper .s1 .inner .cont03 {
    margin-top: 100px; }
    body.support .main .wrapper .s1 .inner .cont03 .cont_inner ul {
      width: 100%;
      font-size: 0; }
      body.support .main .wrapper .s1 .inner .cont03 .cont_inner ul li {
        display: inline-block;
        width: calc(100% / 3);
        vertical-align: middle;
        margin: 10px 0; }
        body.support .main .wrapper .s1 .inner .cont03 .cont_inner ul li img {
          max-width: 100%;
          margin: 0 auto; }
    body.support .main .wrapper .s1 .inner .cont03 .cont_inner p {
      text-align: right; }
  body.support .main .wrapper .s1 .inner #support {
    margin-top: -60px;
    padding-top: 60px; }
  body.support .main .wrapper .s1 .inner #massage, body.support .main .wrapper .s1 .inner #commodity {
    margin-top: -94px;
    padding-top: 94px; }
  body.support .main .wrapper .side-nav ul li:nth-child(3) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.recruit .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/welfare_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.recruit .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hiddeancn;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.recruit .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.recruit .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.recruit .main .wrapper .s1 .inner .anchor {
    padding: 10px 20px;
    background: #ebf1f4;
    border: solid 1px #a3a3a4;
    margin-bottom: 40px; }
    body.recruit .main .wrapper .s1 .inner .anchor li {
      display: inline-block;
      margin-right: 20px; }
    body.recruit .main .wrapper .s1 .inner .anchor a {
      text-decoration: none; }
  body.recruit .main .wrapper .s1 .inner .cont01 .txt_area h4, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area h4 {
    padding: 38px 0 23px;
    font-size: 1.42em;
    color: #002b69;
    line-height: 1; }
  body.recruit .main .wrapper .s1 .inner .cont01 .txt_area table, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d4d4d4; }
    body.recruit .main .wrapper .s1 .inner .cont01 .txt_area table tr, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area table tr {
      border-bottom: 1px solid #d4d4d4; }
      body.recruit .main .wrapper .s1 .inner .cont01 .txt_area table tr th, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area table tr th {
        width: 34.5%;
        background-color: #f2f2f2;
        padding: 20px 17px;
        color: #002b69; }
      body.recruit .main .wrapper .s1 .inner .cont01 .txt_area table tr td, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area table tr td {
        padding: 20px 17px; }
  body.recruit .main .wrapper .s1 .inner .cont01 {
    margin-bottom: 100px; }
  body.recruit .main .wrapper .s1 .inner .page_btn {
    width: 215px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 70px;
    background: #f2f2f2;
    border: solid 1px #a3a3a4;
    text-align: center; }
    body.recruit .main .wrapper .s1 .inner .page_btn a {
      display: block;
      padding: 10px 20px;
      text-decoration: none; }
  body.recruit .main .wrapper .s1 .inner .last {
    margin-bottom: 0; }
  body.recruit .main .wrapper .s1 #recruit01, body.recruit .main .wrapper .s1 #recruit02, body.recruit .main .wrapper .s1 #recruit02-1, body.recruit .main .wrapper .s1 #recruit02-2, body.recruit .main .wrapper .s1 #recruit02-3 {
    margin-top: -94px;
    padding-top: 94px; }
  body.recruit .main .wrapper .side-nav ul li:nth-child(4) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.privacy .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/company_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.privacy .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.privacy .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.privacy .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.privacy .main .wrapper .s1 .inner .wrap p {
    margin-bottom: 20px; }
  body.privacy .main .wrapper .s1 .inner .wrap ol {
    list-style-type: decimal;
    margin: 0 0 20px 20px; }
  body.privacy .main .wrapper .s1 .inner .wrap ul {
    list-style-type: disc;
    margin: 0 0 20px 20px; }
  body.privacy .main .wrapper .s1 .inner .wrap h4 {
    color: #002b69;
    font-size: 1.42em;
    padding: 45px 0 15px; }
  body.privacy .main .wrapper .s1 .inner .wrap h5 {
    color: #002b69;
    font-size: 1.14em;
    padding: 32px 0 10px; }
  body.privacy .main .wrapper .s1 .inner .wrap a {
    text-decoration: none; }
  body.privacy .main .wrapper .s1 .inner .cont01 h3 {
    margin-top: 0; }
  body.privacy .main .wrapper .s1 .inner .cont02 h3 {
    margin-bottom: 0; }
  body.privacy .main .wrapper .s1 .inner .cont02 .txt_area table {
    border-collapse: collapse;
    border-top: 1px solid #ccc;
    width: 100%; }
    body.privacy .main .wrapper .s1 .inner .cont02 .txt_area table tr {
      border-bottom: 1px solid #ccc; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area table tr td {
        width: 25%;
        border-right: 1px solid #ccc;
        padding: 10px;
        font-size: 0.85em;
        vertical-align: top; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area table tr td:last-child {
        border-right: 0; }
  body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table {
    border-collapse: collapse;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 20px; }
    body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr {
      border-bottom: 1px solid #ccc; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr th {
        background-color: #f2f2f2;
        width: 50%;
        border-right: 1px solid #ccc;
        padding: 10px; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr td {
        width: 50%;
        border-right: 1px solid #ccc;
        padding: 20px 10px; }
        body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr td a {
          color: #638a9d; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr td:last-child, body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr th:last-child {
        border-right: 0; }
  body.privacy .main .wrapper .s1 .inner .cont08 .txt_area table {
    border-collapse: collapse;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 20px; }
    body.privacy .main .wrapper .s1 .inner .cont08 .txt_area table tr {
      border-bottom: 1px solid #ccc; }
      body.privacy .main .wrapper .s1 .inner .cont08 .txt_area table tr th {
        background-color: #f2f2f2;
        border-right: 1px solid #ccc;
        padding: 10px; }
      body.privacy .main .wrapper .s1 .inner .cont08 .txt_area table tr td {
        border-right: 1px solid #ccc;
        padding: 20px 10px; }
        body.privacy .main .wrapper .s1 .inner .cont08 .txt_area table tr td a {
          color: #638a9d; }
      body.privacy .main .wrapper .s1 .inner .cont08 .txt_area table tr td:last-child, body.privacy .main .wrapper .s1 .inner .cont08 .txt_area table tr th:last-child {
        border-right: 0; }
  body.privacy .main .wrapper .s1 .inner .cont09 .txt_area .last {
    text-align: right;
    margin-top: 45px; }
  body.privacy .main .wrapper .s1 .inner_02 {
    border-top: 1px solid #ccc;
    margin: 45px 0 0 0;
    padding: 30px 0 0 0; }
    body.privacy .main .wrapper .s1 .inner_02 .cont01 a {
      text-decoration: none;
      color: #638a9d;
      font-size: 1.14em; }
    body.privacy .main .wrapper .s1 .inner_02 .cont01 .last {
      margin-bottom: 30px; }
  /*
index
---------------------------------------- */
  body.faq .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/faq_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.faq .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.faq .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.faq .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.faq .main .wrapper .s1 .inner h2 {
    margin-bottom: 38px; }
  body.faq .main .wrapper .s1 .inner .cont01 dl {
    font-size: 0;
    margin-bottom: 35px; }
    body.faq .main .wrapper .s1 .inner .cont01 dl dt {
      background-color: #ebf1f4;
      padding: 25px 20px 14px;
      font-size: 18px;
      color: #002b69;
      border-bottom: 1px solid #002b69; }
    body.faq .main .wrapper .s1 .inner .cont01 dl dd {
      background-color: #fff5f7;
      padding: 14px 20px 25px; }
      body.faq .main .wrapper .s1 .inner .cont01 dl dd p {
        display: inline-block;
        font-size: 14px;
        width: 91%; }
    body.faq .main .wrapper .s1 .inner .cont01 dl dt::before {
      content: url(../img/faq_q.png);
      vertical-align: middle;
      padding-right: 18px; }
    body.faq .main .wrapper .s1 .inner .cont01 dl dd::before {
      content: url(../img/faq_a.png);
      padding-right: 12px;
      display: inline-block;
      vertical-align: top; }
  body.faq .main .wrapper .s1 .inner .cont01 dl:last-child {
    margin-bottom: none; }
  body.faq .main .wrapper .side-nav ul li:nth-child(3) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.flow .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/flow_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.flow .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.flow .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.flow .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 {
    width: 100%;
    font-size: 0; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 h4 {
      font-size: 1.2rem;
      color: #b3b3b3;
      line-height: 1.2;
      margin-bottom: 10px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border: 2px solid #cbcbcb;
      border-radius: 50%;
      width: 110px;
      height: 110px;
      background-color: #fff; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 h4:after {
      content: "0" counter(support);
      color: #002b69;
      font-size: 2.4rem;
      font-weight: bold; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box {
      width: 100%;
      margin-top: 25px;
      counter-increment: support;
      display: table;
      position: relative;
      margin-bottom: 50px; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box .txt-area {
        display: table-cell;
        vertical-align: middle;
        padding: 0 20px; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box .img-area {
        display: table-cell;
        vertical-align: middle;
        width: 180px; }
        body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box .img-area img {
          max-width: 100%; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box h5 {
        font-size: 20px;
        padding: 0 0 20px;
        line-height: 1;
        font-weight: bold; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box p {
        font-size: 14px; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n-1) {
      background-color: #ebf1f4;
      padding: 20px; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n) {
      background-color: #f2f2f2;
      padding: 20px; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -30px;
      border: 30px solid transparent;
      border-top: 30px solid #e0edff; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n-1):before {
      border-top-color: #ebf1f4; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n):before {
      border-top-color: #f2f2f2; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:last-child:before {
      display: none; }
  body.flow .main .wrapper .side-nav ul li:nth-child(3) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.agency .main .mv {
    position: relative; }
    body.agency .main .mv .img-area img {
      width: 100%;
      padding-top: 94px; }
    body.agency .main .mv .text-area {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 650px;
      height: 500px;
      text-align: center;
      background: url(../img/top_bg.png);
      background-repeat: no-repeat;
      background-position: center; }
      body.agency .main .mv .text-area p {
        color: #fff; }
      body.agency .main .mv .text-area p:nth-of-type(1) {
        margin: 15px 0;
        font-size: 30px;
        line-height: 1.2; }
      body.agency .main .mv .text-area p:nth-of-type(2) {
        margin: 40px 0;
        font-size: 60px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.185em; }
      body.agency .main .mv .text-area p:nth-of-type(3) {
        font-size: 18px;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        margin: 30px auto;
        padding: 5px 0;
        width: 60%; }
      body.agency .main .mv .text-area p:nth-of-type(4) {
        font-size: 16px; }
  body.agency .main section h2 {
    font-family: 'Amiri', serif;
    font-size: 42px;
    color: #b3b3b3;
    letter-spacing: 0.2em; }
  body.agency .main section h3 {
    color: #638a9d;
    font-size: 20px; }
  body.agency .main section .area01 {
    position: relative; }
    body.agency .main section .area01 .inner {
      width: 1124px; }
      body.agency .main section .area01 .inner .text-area {
        width: 1124px;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 500px;
        margin: auto;
        font-size: 0; }
        body.agency .main section .area01 .inner .text-area .inner_box {
          width: 33.33%;
          display: inline-block;
          margin-top: 30px;
          vertical-align: top; }
          body.agency .main section .area01 .inner .text-area .inner_box img {
            max-width: 100%;
            padding: 0 15px;
            margin: auto; }
          body.agency .main section .area01 .inner .text-area .inner_box p {
            font-size: 14px;
            margin: 20px 0 30px;
            padding: 0 15px; }
          body.agency .main section .area01 .inner .text-area .inner_box .inner_box_txt {
            height: 70px; }
          body.agency .main section .area01 .inner .text-area .inner_box a p {
            margin: auto; }
  body.agency .main section .area02 {
    text-align: center; }
    body.agency .main section .area02 .inline .left-box,
    body.agency .main section .area02 .inline .right-box {
      width: 100%;
      background-image: url(../img/agency_bg02.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 100px 0; }
      body.agency .main section .area02 .inline .left-box .text-area h3,
      body.agency .main section .area02 .inline .right-box .text-area h3 {
        color: #fff;
        margin-bottom: 25px; }
      body.agency .main section .area02 .inline .left-box .text-area p,
      body.agency .main section .area02 .inline .right-box .text-area p {
        color: #fff; }
      body.agency .main section .area02 .inline .left-box .text-area .btn03 p,
      body.agency .main section .area02 .inline .right-box .text-area .btn03 p {
        margin: 25px auto 0; }
    body.agency .main section .area02 .inline .left-box {
      position: relative; }
      body.agency .main section .area02 .inline .left-box .text-area {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        height: 250px; }
  body.agency .main section .area03 {
    margin: 100px auto;
    text-align: center; }
    body.agency .main section .area03 .inner {
      width: 1124px;
      font-size: 0; }
    body.agency .main section .area03 .inner_box {
      width: calc(100%/3);
      display: inline-block;
      margin-top: 30px; }
      body.agency .main section .area03 .inner_box img {
        max-width: 100%;
        margin: auto; }
      body.agency .main section .area03 .inner_box p {
        font-size: 14px;
        margin: 20px 0 30px;
        padding: 0 15px;
        text-align: left; }
  /*
index
---------------------------------------- */
  body.contact .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/contact_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.contact .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.contact .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.contact .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.contact .main .wrapper .s1 .tab_area {
    width: 100%;
    background-color: #ebf1f4;
    padding: 18px 20px;
    margin: 18px 0 43px;
    border: 1px #a3a3a4 solid; }
    body.contact .main .wrapper .s1 .tab_area input[type="radio"] {
      margin-right: 10px; }
    body.contact .main .wrapper .s1 .tab_area label {
      margin-right: 25px; }
  body.contact .main .wrapper .s1 .contents_area .contents_area_inner .hully {
    padding: 0 0 20px 0; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner .hully a {
      text-decoration: none;
      color: #638a9d; }
  body.contact .main .wrapper .s1 .contents_area .contents_area_inner table {
    width: 100%;
    border: 1px #ccc solid; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table th {
      border-bottom: 1px #ccc solid;
      padding: 20px;
      background: #f2f2f2;
      width: 34.375%;
      color: #002b69; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table td {
      width: 65.625%;
      padding: 12px;
      border-bottom: 1px #ccc solid;
      border-right: 1px #ccc solid; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table .radio_btn {
      display: inline-block;
      margin-right: 5px; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table .radio_btn label {
        padding-left: 0; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table input[type="text"] {
      width: 290px;
      padding: 8px;
      border: 1px #ccc solid;
      background: #fff; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table label {
      display: inline-block;
      padding: 8px; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table p {
      padding: 4px 0; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table input[type="text"].mail {
      width: 415px; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table input[type="text"].year {
      width: 70px;
      margin: 0 10px 0 0; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table input[type="text"].birthday {
      width: 40px;
      margin: 0 10px; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table select, body.contact .main .wrapper .s1 .contents_area .contents_area_inner table textarea {
      padding: 12px;
      border: 1px #ccc solid;
      background: #fff; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table textarea {
      width: 100%;
      height: 240px; }
  body.contact .main .wrapper .s1 .contents_area .hide {
    display: none; }
  body.contact .main .wrapper .s1 .btn_area {
    text-align: center;
    margin-bottom: 100px; }
    body.contact .main .wrapper .s1 .btn_area p {
      padding: 35px 0 23px; }
    body.contact .main .wrapper .s1 .btn_area a {
      color: #638a9d; }
    body.contact .main .wrapper .s1 .btn_area .submit-hldr .submit {
      width: 26.875%;
      padding: 13px;
      border: 1px #a3a3a4 solid;
      background-color: #f2f2f2;
      cursor: pointer; }
  body.contact .main .wrapper .s1 .area01 h5 {
    font-size: 20px;
    margin-bottom: 15px; }
  body.contact .main .wrapper .s1 .area01 p {
    margin: 15px 0; }
    body.contact .main .wrapper .s1 .area01 p span {
      font-weight: bold; }
  body.contact .main .wrapper .s1 #tell1, body.contact .main .wrapper .s1 #tell2, body.contact .main .wrapper .s1 #tell3 {
    margin-top: -94px;
    padding-top: 94px; }
  body.contact .main .wrapper .side-nav ul li:nth-child(5) a {
    background-color: #638a9d;
    color: #fff; } }

@media (max-width: 767px) {
  /*
 SP common
---------------------------------------- */
  .sp-on {
    display: block; }
  .pc-on {
    display: none; }
  body {
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "游ゴシック", "Yu Gothic", YuGothic, Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 12px;
    line-height: 1.8;
    font-weight: 500;
    color: #333; }
    body header {
      background-color: #fff;
      position: fixed;
      width: 100%;
      z-index: 50;
      font-size: 0;
      overflow: hidden; }
      body header div,
      body header nav {
        font-size: 0;
        display: inline-block; }
      body header .title_hldr {
        margin-top: 16px;
        margin-left: 10px;
        width: calc(100% - 116px); }
        body header .title_hldr img {
          width: 175px; }
      body header .main_menu nav {
        width: 100%;
        display: none; }
        body header .main_menu nav ul li {
          margin: 10px 0;
          border-left: 1px solid #000; }
          body header .main_menu nav ul li a {
            font-size: 14px;
            padding: 10px 20px;
            text-decoration: none; }
      body header .cv_hldr {
        width: 53px;
        height: 53px;
        display: inline-block;
        background-color: #638a9d;
        text-align: center;
        font-size: 9px;
        vertical-align: top;
        letter-spacing: 0.5; }
        body header .cv_hldr a {
          color: #fff;
          text-decoration: none; }
          body header .cv_hldr a img {
            max-width: 45%;
            margin: 5px auto; }
      body header .menubtn_hldr {
        width: 53px;
        height: 53px;
        display: inline-block;
        background-color: #ccc;
        text-align: center;
        font-size: 9px;
        vertical-align: top;
        letter-spacing: 0.5; }
        body header .menubtn_hldr a {
          color: #fff;
          text-decoration: none; }
          body header .menubtn_hldr a img {
            max-width: 45%;
            margin: 5px auto; }
    body .header_cont {
      position: relative;
      z-index: 1;
      width: 100%;
      border-top: 4px solid #b3b3b3; }
    body .accordion_menu {
      background: #ccc;
      padding: 10px; }
      body .accordion_menu li {
        position: relative;
        background: #fff;
        border-bottom: solid 1px #ccc;
        font-size: 13px; }
      body .accordion_menu li::after {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        content: "＞"; }
      body .accordion_menu a {
        color: #000;
        display: block;
        padding: 10px;
        text-decoration: none; }
    body header:after {
      content: ".";
      line-height: 0;
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100%/3);
      border-top: 4px solid #638a9d;
      z-index: 51; }
    body header:before {
      content: ".";
      line-height: 0;
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: calc(100%/3);
      border-top: 4px solid #09469b;
      z-index: 51; }
    body .pankuzu {
      padding: 7px 20px;
      border-bottom: 1px solid #ccc; }
      body .pankuzu ul {
        font-size: 0; }
        body .pankuzu ul li {
          display: inline-block;
          font-size: 10px; }
          body .pankuzu ul li a {
            text-decoration: none; }
        body .pankuzu ul li::after {
          content: ">";
          margin: 0 5px; }
        body .pankuzu ul li:last-child::after {
          content: none; }
    body section .btn01,
    body section .btn02,
    body section .btn03 {
      text-decoration: none; }
      body section .btn01 p,
      body section .btn02 p,
      body section .btn03 p {
        width: 100%;
        height: 45px;
        border-radius: 30px;
        background-color: #fff;
        text-align: center; }
    body section .btn01 p {
      border: solid 2px #638a9d;
      color: #638a9d;
      padding-top: 11px; }
    body section .btn02 p {
      border: solid 2px #002b69;
      color: #002b69;
      padding-top: 11px; }
    body section .btn03 p {
      border: solid 2px #fff;
      color: #fff;
      background-color: inherit;
      padding-top: 11px; }
    body section .img-area img {
      width: 100%; }
    body .wrapper {
      width: 100%;
      margin: 0 auto 87px;
      padding: 0 20px;
      overflow: hidden; }
      body .wrapper section {
        margin-top: 30px; }
        body .wrapper section h2 {
          box-sizing: border-box;
          font-size: 1.71em;
          border-top: 3px solid #ccc;
          border-bottom: 1px solid #ccc;
          padding: 15px 0;
          line-height: 1.2;
          margin-bottom: 12px; }
        body .wrapper section h3 {
          box-sizing: border-box;
          font-size: 1.57em;
          padding: 14px;
          line-height: 1;
          background-color: #f2f2f2;
          margin: 29px 0 20px;
          border-radius: 4px; }
    body footer {
      border-top: 1px solid #ccc;
      overflow: hidden; }
      body footer .form-area {
        padding: 20px;
        background-color: #fff;
        margin: auto;
        text-align: center;
        font-size: 0.9em; }
        body footer .form-area .cont_01 {
          overflow: hidden; }
          body footer .form-area .cont_01 .access p {
            line-height: 1.47; }
            body footer .form-area .cont_01 .access p .color_txt {
              font-size: 1.2em;
              color: #09469b;
              margin-right: 1em; }
          body footer .form-area .cont_01 .tel {
            display: inline-block;
            font-size: 2.37em;
            vertical-align: middle;
            font-family: Lato;
            font-weight: 400;
            color: #002b69;
            float: left; }
            body footer .form-area .cont_01 .tel img {
              display: inline-block;
              vertical-align: middle;
              width: 12px;
              margin: 0 3px 3px 0; }
          body footer .form-area .cont_01 .btn_form {
            display: inline-block;
            float: right; }
            body footer .form-area .cont_01 .btn_form a {
              width: 105px;
              height: 29px;
              text-align: center;
              display: block;
              background-color: #09469b;
              color: #fff;
              text-decoration: none;
              border-radius: 14px;
              padding: 5px 0 0 0;
              margin: 4px 0 0 0; }
      body footer .link_area {
        background-color: #ccc;
        padding: 0 20px; }
        body footer .link_area .inner {
          padding: 20px 0 30px; }
          body footer .link_area .inner p:last-child {
            color: #638a9d;
            font-size: 0.9em;
            text-align: center;
            padding-top: 16px; }
          body footer .link_area .inner .cont_left {
            text-align: center;
            padding: 0 0 12px 0;
            font-size: 12px; }
            body footer .link_area .inner .cont_left p:nth-child(1) {
              font-weight: bold; }
            body footer .link_area .inner .cont_left .img_hldr {
              font-size: 0;
              margin-top: 5px; }
              body footer .link_area .inner .cont_left .img_hldr img {
                width: 100px;
                display: inline-block;
                margin-right: 5px; }
              body footer .link_area .inner .cont_left .img_hldr img:nth-child(2) {
                margin-right: 0; }
          body footer .link_area .inner .cont_right ul {
            margin-right: 60px;
            width: 100%;
            border: 1px solid #666; }
            body footer .link_area .inner .cont_right ul li {
              border-bottom: 1px solid #666; }
              body footer .link_area .inner .cont_right ul li a {
                text-decoration: none;
                display: block;
                width: 100%;
                color: #666;
                padding: 9px; }
            body footer .link_area .inner .cont_right ul li:last-child {
              border-bottom: 0; }
          body footer .link_area .inner .cont_right ul:last-child {
            margin-right: 0; }
  /*
index
---------------------------------------- */
  body.home .main .mv {
    position: relative;
    overflow: hidden; }
    body.home .main .mv .img-area {
      height: 584px; }
      body.home .main .mv .img-area #bgvid {
        padding-top: 57px;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
        left: 0; }
      body.home .main .mv .img-area img {
        width: 100%;
        padding-top: 57px; }
    body.home .main .mv .text-area {
      position: absolute;
      margin: 0 auto;
      top: 35%;
      bottom: 0;
      right: 0;
      left: 0;
      width: 80%;
      text-align: center;
      background: url(../img/top_bg_sp.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top; }
      body.home .main .mv .text-area p {
        color: #fff; }
      body.home .main .mv .text-area p:nth-of-type(1) {
        margin: 15px 0;
        font-size: 2.7em;
        line-height: 1.29; }
      body.home .main .mv .text-area p:nth-of-type(2) {
        font-size: 1.42em;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        margin: 0 auto;
        padding: 5px 0;
        width: 93%; }
      body.home .main .mv .text-area p:nth-of-type(3) {
        margin-top: 15px;
        line-height: 1.63;
        font-size: 1.1em; }
  body.home .main section h2 {
    font-family: 'Amiri', serif;
    font-size: 2.5em;
    color: #b3b3b3;
    line-height: 1;
    margin-bottom: 10px; }
  body.home .main section h3 {
    color: #638a9d;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 15px; }
  body.home .main section .area01 {
    position: relative; }
    body.home .main section .area01 .img-area {
      width: 100%; }
      body.home .main section .area01 .img-area img {
        width: 100%; }
    body.home .main section .area01 .inner {
      width: 100%; }
      body.home .main section .area01 .inner .text-area {
        padding: 0 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 350px;
        margin: auto; }
        body.home .main section .area01 .inner .text-area p:nth-child(3) {
          margin: 30px 0; }
        body.home .main section .area01 .inner .text-area p {
          line-height: 1.6;
          margin: 0 0 20px 0; }
  body.home .main section .area02 {
    margin: 50px 20px; }
    body.home .main section .area02 p {
      margin-top: 15px; }
    body.home .main section .area02 .inner {
      margin-top: 20px; }
      body.home .main section .area02 .inner .left-box {
        margin-bottom: 10px; }
      body.home .main section .area02 .inner .left-box, body.home .main section .area02 .inner .right-box {
        width: 100%; }
        body.home .main section .area02 .inner .left-box .text-area, body.home .main section .area02 .inner .right-box .text-area {
          padding: 25px 10px; }
          body.home .main section .area02 .inner .left-box .text-area h4, body.home .main section .area02 .inner .right-box .text-area h4 {
            font-size: 1.2em;
            color: #002b69;
            text-align: center; }
          body.home .main section .area02 .inner .left-box .text-area p, body.home .main section .area02 .inner .right-box .text-area p {
            margin: 0 auto;
            line-height: 1.6; }
      body.home .main section .area02 .inner .left-box {
        position: relative; }
        body.home .main section .area02 .inner .left-box .text-area {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto; }
      body.home .main section .area02 .inner .right-box {
        position: relative; }
        body.home .main section .area02 .inner .right-box .text-area {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto; }
    body.home .main section .area02 .btn02 p {
      margin: 15px auto 0; }
  body.home .main section .area03 {
    text-align: center; }
    body.home .main section .area03 .inline .left-box, body.home .main section .area03 .inline .right-box {
      width: 100%; }
      body.home .main section .area03 .inline .left-box .text-area, body.home .main section .area03 .inline .right-box .text-area {
        padding: 49px 0; }
        body.home .main section .area03 .inline .left-box .text-area h3, body.home .main section .area03 .inline .right-box .text-area h3 {
          color: #fff; }
        body.home .main section .area03 .inline .left-box .text-area p, body.home .main section .area03 .inline .right-box .text-area p {
          color: #fff;
          width: 64%;
          margin: auto; }
        body.home .main section .area03 .inline .left-box .text-area .btn03 p, body.home .main section .area03 .inline .right-box .text-area .btn03 p {
          margin: 14px auto 0;
          width: 87.5%; }
    body.home .main section .area03 .inline .left-box .text-area {
      background-image: url(../img/index04.png);
      background-position: right;
      background-size: cover; }
    body.home .main section .area03 .inline .right-box .text-area {
      background-image: url(../img/index05.png);
      background-position: right;
      background-size: cover; }
    body.home .main section .area03 .box {
      background-image: url(../img/index06.png);
      background-position: center;
      background-size: cover;
      padding: 20px 0; }
      body.home .main section .area03 .box .text-area {
        margin: auto;
        width: 93.75%;
        margin: auto;
        padding: 35px 10px 20px;
        background-color: #fff; }
        body.home .main section .area03 .box .text-area h3 {
          font-size: 1.2em;
          line-height: 1.45; }
        body.home .main section .area03 .box .text-area .inline {
          justify-content: space-around;
          width: 80%;
          margin: auto; }
          body.home .main section .area03 .box .text-area .inline .btn01 p {
            margin-bottom: 5px; }
        body.home .main section .area03 .box .text-area p:nth-child(3) {
          margin-bottom: 44px; }
        body.home .main section .area03 .box .text-area p:nth-child(4) {
          margin-bottom: 20px;
          position: relative; }
        body.home .main section .area03 .box .text-area p:nth-child(4):before {
          content: "商材一例";
          padding: 4px 9px;
          background-color: #638a9d;
          color: #fff;
          text-align: center;
          position: absolute;
          top: -28px;
          left: 40%; }
  /*
index
---------------------------------------- */
  body.training .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/training_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.training .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.training .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.training .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.training .main .wrapper .s1 .inner .cont01 .txt_area {
    background-color: #ebf1f4;
    padding: 17px; }
    body.training .main .wrapper .s1 .inner .cont01 .txt_area h4 {
      font-size: 1.565em;
      color: #002b69;
      line-height: 1.18;
      margin-bottom: 14px;
      font-weight: bold; }
  body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 {
    width: 100%; }
    body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box {
      margin-bottom: 20px; }
      body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box img {
        width: 100%; }
      body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box h5 {
        padding: 10px 0;
        font-size: 1.42em; }
    body.training .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box:last-child {
      margin-bottom: 0; }
  body.training .main .wrapper .side-nav ul li:nth-child(4) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.welfare .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/training_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.welfare .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.welfare .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.welfare .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.welfare .main .wrapper .s1 .inner .cont01 .txt_area h4 {
    padding: 19px 0 12px;
    font-size: 1.42em;
    color: #002b69;
    line-height: 1;
    font-weight: bold; }
  body.welfare .main .wrapper .s1 .inner .cont01 .txt_area table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d4d4d4; }
    body.welfare .main .wrapper .s1 .inner .cont01 .txt_area table tr {
      border-bottom: 1px solid #d4d4d4; }
      body.welfare .main .wrapper .s1 .inner .cont01 .txt_area table tr th {
        width: 34.5%;
        background-color: #f2f2f2;
        padding: 10px 8px;
        color: #002b69; }
      body.welfare .main .wrapper .s1 .inner .cont01 .txt_area table tr td {
        padding: 10px 8px; }
  body.welfare .main .wrapper .side-nav ul li:nth-child(4) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.business .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/business_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.business .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.business .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.business .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.business .main .wrapper .s1 .inner .cont01 .b_01 {
    background-image: url(../img/business_bg01.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 172px;
    position: relative; }
    body.business .main .wrapper .s1 .inner .cont01 .b_01 .txt_area {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      right: 8%; }
  body.business .main .wrapper .s1 .inner .cont01 .b_02 {
    background-image: url(../img/business_bg02.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 172px;
    position: relative; }
    body.business .main .wrapper .s1 .inner .cont01 .b_02 .txt_area {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      left: 10%; }
  body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box:nth-of-type(3) {
    background-image: url(../img/business_bg03.png);
    margin-bottom: 0; }
  body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box {
    margin-bottom: 7px; }
    body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area {
      text-align: center;
      color: #fff;
      line-height: 1; }
      body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area p {
        font-size: 2.71em;
        padding: 0 5px 8px;
        border-bottom: 1px solid #fff; }
      body.business .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area h4 {
        padding: 8px 0 0 0;
        font-size: 1.14em; }
  body.business .main .wrapper .s1 .inner .cont_inner {
    margin: 37px 0 0 0; }
    body.business .main .wrapper .s1 .inner .cont_inner .cont_inner_txt {
      width: 100%;
      padding: 28px 0 0 0; }
      body.business .main .wrapper .s1 .inner .cont_inner .cont_inner_txt h5 {
        color: #002b69;
        font-size: 1.42em;
        margin-bottom: 16px; }
      body.business .main .wrapper .s1 .inner .cont_inner .cont_inner_txt 　p {
        line-height: 1.85; }
  /*
index
---------------------------------------- */
  body.company .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/company_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.company .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.company .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.company .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.company .main .wrapper .s1 .inner .cont01 .txt_area {
    background-color: #ebf1f4;
    padding: 16px 15px;
    overflow: hidden;
    margin-bottom: 21px; }
    body.company .main .wrapper .s1 .inner .cont01 .txt_area h4 {
      font-size: 1.57em;
      color: #002b69;
      line-height: 1.3;
      font-weight: bold; }
    body.company .main .wrapper .s1 .inner .cont01 .txt_area .color_txt {
      font-size: 1.14em;
      color: #002b69;
      padding: 9px 0 16px;
      line-height: 1.3; }
    body.company .main .wrapper .s1 .inner .cont01 .txt_area img {
      padding-top: 10px;
      float: right;
      width: 70%; }
  body.company .main .wrapper .s1 .inner .cont02 .txt_area table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d4d4d4; }
    body.company .main .wrapper .s1 .inner .cont02 .txt_area table tr {
      border-bottom: 1px solid #d4d4d4; }
      body.company .main .wrapper .s1 .inner .cont02 .txt_area table tr th {
        width: 34.5%;
        background-color: #f2f2f2;
        padding: 10px 9px;
        color: #002b69; }
      body.company .main .wrapper .s1 .inner .cont02 .txt_area table tr td {
        padding: 10px 9px; }
  body.company .main .wrapper .s1 .inner_02 {
    margin: 42px 0 0 0; }
    body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box {
      vertical-align: top;
      width: 100%;
      display: inline-block;
      margin: 0 0 23px 0; }
      body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box h4 {
        font-size: 1.42em;
        color: #002b69; }
      body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box p {
        letter-spacing: -0.03em; }
    body.company .main .wrapper .s1 .inner_02 .cont01 .inner_box:last-child {
      margin-bottom: 0; }
  /*
index
---------------------------------------- */
  body.service .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/service_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.service .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.service .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.service .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.service .main .wrapper .s1 .inner .cont01 .b_01 {
    background-image: url(../img/service_bg01.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 259px;
    position: relative; }
    body.service .main .wrapper .s1 .inner .cont01 .b_01 .txt_area {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      right: 8%; }
  body.service .main .wrapper .s1 .inner .cont01 .b_02 {
    background-image: url(../img/service_bg02.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 259px;
    position: relative; }
    body.service .main .wrapper .s1 .inner .cont01 .b_02 .txt_area {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      left: 10%; }
  body.service .main .wrapper .s1 .inner .cont01 .inner_cont_box {
    margin-bottom: 13px; }
    body.service .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area {
      text-align: center;
      color: #fff;
      line-height: 1; }
      body.service .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area p {
        font-size: 2.71em;
        padding: 0 10px 15px;
        border-bottom: 1px solid #fff; }
      body.service .main .wrapper .s1 .inner .cont01 .inner_cont_box .txt_area h4 {
        padding: 15px 0 0 0;
        font-size: 1.14em; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 p:nth-child(1) {
    margin: 30px 0; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box {
    position: relative; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box .img_area img {
      width: 100%;
      height: 130px; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box .txt_area {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      height: 90px;
      text-align: center;
      padding: 0 3%; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box .txt_area h4 {
        font-size: 1.2em;
        color: #09469b;
        line-height: 1;
        margin-bottom: 8px;
        font-weight: bold; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box .txt_area p {
        line-height: 1.2; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area01 .inner_box:nth-of-type(1) {
    margin-bottom: 13px; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 {
    width: 100%; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 .inner_box {
      width: 100%;
      vertical-align: top;
      margin-top: 25px;
      padding-bottom: 30px; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 .inner_box img {
        width: 100%; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 .inner_box p {
        font-size: 12px;
        margin-bottom: 10px; }
      body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area02 .inner_box .subtxt {
        font-size: 10px;
        text-align: right;
        margin-top: 5px; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box .img_area {
    width: 100%; }
    body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box .img_area img {
      width: 100%; }
  body.service .main .wrapper .s1 .inner .cont_inner .cont_inner_area03 .inner_box .txt_area p {
    margin-top: 10px;
    margin-bottom: 0; }
  body.service .main .wrapper .side-nav ul li:nth-child(1) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.support .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/support_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.support .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.support .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.support .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.support .main .wrapper .s1 .inner .cont01, body.support .main .wrapper .s1 .inner .cont02 {
    margin-bottom: 50px; }
    body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 {
      width: 100%;
      font-size: 0; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 h4, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 h4, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 h4, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 h4 {
        font-family: 'Amiri', serif;
        font-size: 1.8rem;
        color: #b3b3b3;
        line-height: 1;
        margin-bottom: 10px; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 h4:after, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 h4:after, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 h4:after, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 h4:after {
        content: counter(support);
        font-size: 2.5rem; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-right: 24px;
        margin-top: 25px;
        counter-increment: support; }
        body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box img, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box img, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box img, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box img {
          width: 100%; }
        body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box h5, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box h5, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box h5, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box h5 {
          font-size: 20px;
          padding: 20px 0 15px;
          line-height: 1.2;
          font-weight: bold; }
        body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box p, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box p, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box p, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box p {
          font-size: 14px; }
        body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box h6, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box h6, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box h6, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box h6 {
          color: #002b69;
          font-size: 12px;
          font-weight: bold;
          text-align: right; }
          body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box h6 span, body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box h6 span, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box h6 span, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box h6 span {
            font-size: 18px;
            margin-left: 10px; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n), body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box:nth-child(2n), body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n), body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box:nth-child(2n) {
        margin-right: 0; }
      body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(n+3), body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box:nth-child(n+3), body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area01 .inner_box:nth-child(n+3), body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box:nth-child(n+3) {
        margin-top: 50px; }
    body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box img, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box img {
      width: auto;
      max-width: 100%;
      margin: 0 auto; }
    body.support .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area02 .inner_box .txt-area, body.support .main .wrapper .s1 .inner .cont02 .cont_inner .cont_inner_area02 .inner_box .txt-area {
      border-radius: 10px;
      border: solid 1px #cbcbcb;
      padding: 0 20px 20px; }
  body.support .main .wrapper .s1 .inner .cont02 {
    margin-top: 100px; }
  body.support .main .wrapper .s1 .inner .cont03 {
    margin-top: 100px; }
    body.support .main .wrapper .s1 .inner .cont03 .cont_inner ul {
      width: 100%;
      font-size: 0; }
      body.support .main .wrapper .s1 .inner .cont03 .cont_inner ul li {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        margin: 20px 0; }
        body.support .main .wrapper .s1 .inner .cont03 .cont_inner ul li img {
          margin: 0 auto; }
    body.support .main .wrapper .s1 .inner .cont03 .cont_inner p {
      text-align: right;
      font-size: 14px; }
  body.support .main .wrapper .s1 .inner #support {
    margin-top: -30px;
    padding-top: 57px; }
  body.support .main .wrapper .s1 .inner #massage, body.support .main .wrapper .s1 .inner #commodity {
    margin-top: -57px;
    padding-top: 57px; }
  body.support .main .wrapper .side-nav ul li:nth-child(3) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.recruit .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/welfare_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.recruit .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.recruit .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.recruit .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.recruit .main .wrapper .s1 .inner .anchor {
    padding: 10px 20px;
    background: #ebf1f4;
    border: solid 1px #a3a3a4;
    margin-bottom: 40px; }
    body.recruit .main .wrapper .s1 .inner .anchor li {
      display: inline-block;
      width: 100%; }
    body.recruit .main .wrapper .s1 .inner .anchor a {
      text-decoration: none; }
  body.recruit .main .wrapper .s1 .inner .cont01 .txt_area h4, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area h4 {
    padding: 19px 0 12px;
    font-size: 1.42em;
    color: #002b69;
    line-height: 1; }
  body.recruit .main .wrapper .s1 .inner .cont01 .txt_area table, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d4d4d4; }
    body.recruit .main .wrapper .s1 .inner .cont01 .txt_area table tr, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area table tr {
      border-bottom: 1px solid #d4d4d4; }
      body.recruit .main .wrapper .s1 .inner .cont01 .txt_area table tr th, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area table tr th {
        width: 34.5%;
        background-color: #f2f2f2;
        padding: 10px 8px;
        color: #002b69; }
      body.recruit .main .wrapper .s1 .inner .cont01 .txt_area table tr td, body.recruit .main .wrapper .s1 .inner .cont02 .txt_area table tr td {
        padding: 10px 8px; }
  body.recruit .main .wrapper .s1 .inner .cont01 {
    margin-bottom: 42px; }
  body.recruit .main .wrapper .s1 .inner .page_btn {
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 70px;
    background: #f2f2f2;
    border: solid 1px #a3a3a4;
    text-align: center; }
    body.recruit .main .wrapper .s1 .inner .page_btn a {
      display: block;
      padding: 10px 20px;
      text-decoration: none; }
  body.recruit .main .wrapper .s1 .inner .last {
    margin-bottom: 0; }
  body.recruit .main .wrapper .s1 #recruit01, body.recruit .main .wrapper .s1 #recruit02, body.recruit .main .wrapper .s1 #recruit02-1, body.recruit .main .wrapper .s1 #recruit02-2, body.recruit .main .wrapper .s1 #recruit02-3 {
    margin-top: -57px;
    padding-top: 57px; }
  body.recruit .main .wrapper .side-nav ul li:nth-child(4) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.privacy .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/company_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.privacy .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.privacy .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.privacy .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.privacy .main .wrapper .s1 .inner .wrap ol {
    list-style-type: decimal;
    margin-left: 15px; }
  body.privacy .main .wrapper .s1 .inner .wrap ul {
    list-style-type: disc;
    margin-left: 15px; }
  body.privacy .main .wrapper .s1 .inner .wrap h4 {
    color: #002b69;
    font-size: 1.42em;
    padding: 23px 0 8px; }
  body.privacy .main .wrapper .s1 .inner .wrap h5 {
    color: #002b69;
    font-size: 1.14em;
    padding: 16px 0 5px; }
  body.privacy .main .wrapper .s1 .inner .wrap a {
    text-decoration: none; }
  body.privacy .main .wrapper .s1 .inner .cont01 h3 {
    margin-top: 0; }
  body.privacy .main .wrapper .s1 .inner .cont02 h3 {
    margin-bottom: 0; }
  body.privacy .main .wrapper .s1 .inner .cont02 .txt_area table {
    border-collapse: collapse;
    border-top: 1px solid #ccc;
    width: 100%; }
    body.privacy .main .wrapper .s1 .inner .cont02 .txt_area table tr {
      border-bottom: 1px solid #ccc; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area table tr td {
        width: 25%;
        border-right: 1px solid #ccc;
        padding: 5px;
        font-size: 0.85em; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area table tr td:last-child {
        border-right: 0; }
  body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table {
    border-collapse: collapse;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px; }
    body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr {
      border-bottom: 1px solid #ccc; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr th {
        background-color: #f2f2f2;
        width: 50%;
        border-right: 1px solid #ccc;
        padding: 5px; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr td {
        width: 50%;
        border-right: 1px solid #ccc;
        padding: 10px 5px; }
        body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr td a {
          color: #638a9d; }
      body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr td:last-child, body.privacy .main .wrapper .s1 .inner .cont02 .txt_area_02 table tr th:last-child {
        border-right: 0; }
  body.privacy .main .wrapper .s1 .inner .cont09 .txt_area .last {
    text-align: right;
    margin-top: 23px; }
  body.privacy .main .wrapper .s1 .inner_02 {
    border-top: 1px solid #ccc;
    margin: 23px 0 0 0;
    padding: 15px 0 0 0; }
    body.privacy .main .wrapper .s1 .inner_02 .cont01 a {
      text-decoration: none;
      color: #638a9d;
      font-size: 1.14em; }
    body.privacy .main .wrapper .s1 .inner_02 .cont01 .last {
      margin-bottom: 15px; }
  /*
index
---------------------------------------- */
  body.faq .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/faq_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.faq .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.faq .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.faq .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.faq .main .wrapper .s1 .inner h2 {
    margin-bottom: 19px; }
  body.faq .main .wrapper .s1 .inner .cont01 dl {
    font-size: 0;
    margin-bottom: 18px; }
    body.faq .main .wrapper .s1 .inner .cont01 dl dt {
      background-color: #ebf1f4;
      padding: 13px 10px 7px;
      font-size: 18px;
      color: #002b69;
      border-bottom: 1px solid #002b69; }
    body.faq .main .wrapper .s1 .inner .cont01 dl dd {
      background-color: #fff5f7;
      padding: 7px 10px 13px; }
      body.faq .main .wrapper .s1 .inner .cont01 dl dd p {
        display: inline-block;
        font-size: 12px;
        width: 91%; }
    body.faq .main .wrapper .s1 .inner .cont01 dl dt::before {
      content: url(../img/faq_q.png);
      vertical-align: middle;
      padding-right: 9px; }
    body.faq .main .wrapper .s1 .inner .cont01 dl dd::before {
      content: url(../img/faq_a.png);
      padding-right: 10px;
      display: inline-block;
      vertical-align: top; }
  body.faq .main .wrapper .s1 .inner .cont01 dl:last-child {
    margin-bottom: none; }
  body.faq .main .wrapper .side-nav ul li:nth-child(3) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.flow .main .mv .title-area {
    width: 100%;
    height: 344px;
    padding-top: 94px;
    background-image: url(../img/flow_main.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat; }
    body.flow .main .mv .title-area .main_tit {
      width: 80%;
      color: #fff;
      margin: auto;
      overflow: hidden;
      font-size: 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%); }
      body.flow .main .mv .title-area .main_tit h1 {
        font-size: 32px;
        display: inline-block;
        line-height: 1; }
      body.flow .main .mv .title-area .main_tit p {
        display: inline-block;
        font-size: 18px;
        margin-left: 27px;
        line-height: 1;
        position: absolute;
        top: 8px; }
  body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 {
    width: 100%;
    font-size: 0; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 h4 {
      font-size: 1.2rem;
      color: #b3b3b3;
      line-height: 1.2;
      margin: 0 auto 10px;
      text-align: center;
      vertical-align: middle;
      border: 2px solid #cbcbcb;
      border-radius: 50%;
      width: 110px;
      height: 110px;
      background-color: #fff;
      padding-top: 20px; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 h4:after {
      content: "0" counter(support);
      color: #002b69;
      font-size: 2.4rem;
      font-weight: bold; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box {
      width: 100%;
      margin-top: 25px;
      counter-increment: support;
      position: relative;
      margin-bottom: 80px;
      vertical-align: center; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box .txt-area {
        padding: 0 20px;
        text-align: center; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box .txt-area:after {
        display: block;
        content: "";
        clear: both; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box .img-area {
        max-width: 180px;
        margin: 10px auto; }
        body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box .img-area img {
          max-width: 100%; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box h5 {
        font-size: 20px;
        padding: 0 0 20px;
        line-height: 1;
        font-weight: bold; }
      body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box p {
        font-size: 14px; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n-1) {
      background-color: #ebf1f4;
      padding: 20px; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n) {
      background-color: #f2f2f2;
      padding: 20px; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -50px;
      border: 50px solid transparent;
      border-top: 50px solid #e0edff; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n-1):before {
      border-top-color: #ebf1f4; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:nth-child(2n):before {
      border-top-color: #f2f2f2; }
    body.flow .main .wrapper .s1 .inner .cont01 .cont_inner .cont_inner_area01 .inner_box:last-child:before {
      display: none; }
  body.flow .main .wrapper .side-nav ul li:nth-child(3) a {
    background-color: #638a9d;
    color: #fff; }
  /*
index
---------------------------------------- */
  body.agency .main .mv {
    position: relative; }
    body.agency .main .mv .img-area img {
      width: 100%; }
    body.agency .main .mv .text-area {
      position: absolute;
      margin: 0 auto;
      top: 25%;
      bottom: 0;
      right: 0;
      left: 0;
      width: 80%;
      height: 300px;
      text-align: center;
      background: url(../img/top_bg.png);
      background-repeat: no-repeat;
      background-position: center; }
      body.agency .main .mv .text-area p {
        color: #fff; }
      body.agency .main .mv .text-area p:nth-of-type(1) {
        margin: 15px 0;
        font-size: 1.7em;
        line-height: 1.2; }
      body.agency .main .mv .text-area p:nth-of-type(2) {
        margin: 15px 0;
        font-size: 3.4em;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.185em; }
      body.agency .main .mv .text-area p:nth-of-type(3) {
        font-size: 1.28em;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        margin: 0 auto;
        padding: 5px 0;
        width: 93%; }
      body.agency .main .mv .text-area p:nth-of-type(4) {
        margin-top: 15px;
        line-height: 1.63;
        font-size: 1em; }
  body.agency .main section h2 {
    font-family: 'Amiri', serif;
    font-size: 30px;
    color: #b3b3b3;
    line-height: 1;
    margin-bottom: 10px; }
  body.agency .main section h3 {
    color: #638a9d;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 15px; }
  body.agency .main section .area01 {
    padding: 50px 0;
    text-align: center;
    background: url(../img/agency_bg01_sp.png);
    background-size: 100% auto; }
    body.agency .main section .area01 .inner .text-area {
      margin: auto;
      font-size: 0;
      padding: 0 20px; }
      body.agency .main section .area01 .inner .text-area .inner_box {
        width: 100%;
        display: inline-block;
        margin-top: 30px; }
        body.agency .main section .area01 .inner .text-area .inner_box img {
          width: 100%;
          margin: auto; }
        body.agency .main section .area01 .inner .text-area .inner_box p {
          text-align: left;
          font-size: 14px;
          margin: 20px 0 30px; }
        body.agency .main section .area01 .inner .text-area .inner_box a p {
          margin: auto;
          text-align: center;
          width: 90%; }
        body.agency .main section .area01 .inner .text-area .inner_box .btn01 p {
          border: solid 2px #638a9d;
          color: #638a9d;
          padding-top: 8px !important; }
  body.agency .main section .area02 {
    text-align: center; }
    body.agency .main section .area02 .inline .left-box, body.agency .main section .area02 .inline .right-box {
      width: 100%; }
      body.agency .main section .area02 .inline .left-box .text-area, body.agency .main section .area02 .inline .right-box .text-area {
        padding: 49px 0; }
        body.agency .main section .area02 .inline .left-box .text-area h3, body.agency .main section .area02 .inline .right-box .text-area h3 {
          color: #fff; }
        body.agency .main section .area02 .inline .left-box .text-area p, body.agency .main section .area02 .inline .right-box .text-area p {
          color: #fff;
          width: 64%;
          margin: auto; }
        body.agency .main section .area02 .inline .left-box .text-area .btn03 p, body.agency .main section .area02 .inline .right-box .text-area .btn03 p {
          margin: 14px auto 0;
          width: 87.5%; }
    body.agency .main section .area02 .inline .left-box .text-area {
      background-image: url(../img/agency_bg02.png);
      background-position: right;
      background-size: cover; }
    body.agency .main section .area02 .inline .right-box .text-area {
      background-image: url(../img/agency_bg03.png);
      background-position: right;
      background-size: cover; }
  body.agency .main section .area03 {
    margin: 50px auto 100px;
    text-align: center;
    padding: 0 20px; }
    body.agency .main section .area03 .inner {
      font-size: 0; }
    body.agency .main section .area03 .inner_box {
      width: 100%;
      display: inline-block;
      margin-top: 30px; }
      body.agency .main section .area03 .inner_box img {
        width: 100%;
        margin: auto; }
      body.agency .main section .area03 .inner_box p {
        font-size: 14px;
        margin: 20px 0 0;
        text-align: left; }
  /*
index
---------------------------------------- */
  body.contact .main .mv .title-area {
    width: 100%;
    height: 230px;
    padding-top: 61px;
    background-image: url(../img/contact_main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    body.contact .main .mv .title-area .main_tit {
      color: #fff;
      padding: 0 20px 0;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      margin: auto; }
      body.contact .main .mv .title-area .main_tit h1 {
        font-size: 2.28em; }
      body.contact .main .mv .title-area .main_tit p {
        font-size: 1.28em; }
  body.contact .main .wrapper .s1 {
    float: none; }
    body.contact .main .wrapper .s1 .tab_area {
      width: 100%;
      background-color: #ebf1f4;
      padding: 18px 20px;
      margin: 18px 0 43px;
      border: 1px #a3a3a4 solid; }
      body.contact .main .wrapper .s1 .tab_area input[type="radio"] {
        margin-right: 10px; }
      body.contact .main .wrapper .s1 .tab_area label {
        display: inline-block;
        width: 100%; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner .hully {
      padding: 0 0 20px 0; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner .hully a {
        text-decoration: none;
        color: #638a9d; }
    body.contact .main .wrapper .s1 .contents_area .contents_area_inner table {
      width: 100%;
      border: 1px #ccc solid; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table th {
        border-bottom: 1px #ccc solid;
        padding: 20px;
        background: #f2f2f2;
        width: 30%;
        color: #002b69; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table td {
        width: 70%;
        padding: 12px;
        border-bottom: 1px #ccc solid;
        border-right: 1px #ccc solid; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table .radio_btn {
        display: inline-block;
        margin-right: 5px; }
        body.contact .main .wrapper .s1 .contents_area .contents_area_inner table .radio_btn label {
          padding-left: 0; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table input[type="text"] {
        width: 70%;
        padding: 6px 8px;
        border: 1px #ccc solid;
        background: #fff; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table label {
        display: inline-block;
        padding: 8px; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table p {
        padding: 4px 0; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table input[type="text"].mail {
        width: 90%; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table input[type="text"].year {
        width: 70px;
        margin: 0 10px 0 0; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table input[type="text"].birthday {
        width: 40px;
        margin: 0 10px; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table select, body.contact .main .wrapper .s1 .contents_area .contents_area_inner table textarea {
        padding: 12px;
        border: 1px #ccc solid;
        background: #fff; }
      body.contact .main .wrapper .s1 .contents_area .contents_area_inner table textarea {
        width: 100%;
        height: 240px; }
    body.contact .main .wrapper .s1 .contents_area .hide {
      display: none; }
    body.contact .main .wrapper .s1 .btn_area {
      text-align: center;
      margin-bottom: 50px; }
      body.contact .main .wrapper .s1 .btn_area p {
        padding: 35px 0 23px; }
      body.contact .main .wrapper .s1 .btn_area a {
        color: #638a9d; }
      body.contact .main .wrapper .s1 .btn_area .submit-hldr .submit {
        width: 26.875%;
        padding: 8px 15px;
        border: 1px #a3a3a4 solid;
        background-color: #f2f2f2;
        cursor: pointer; }
    body.contact .main .wrapper .s1 .area01 h5 {
      font-size: 16px;
      margin-bottom: 15px; }
    body.contact .main .wrapper .s1 .area01 p {
      margin: 15px 0; }
      body.contact .main .wrapper .s1 .area01 p span {
        font-weight: bold; }
    body.contact .main .wrapper .s1 #tell1, body.contact .main .wrapper .s1 #tell2, body.contact .main .wrapper .s1 #tell3 {
      margin-top: -57px;
      padding-top: 57px; }
  body.contact .main .wrapper .side-nav ul li:nth-child(5) a {
    background-color: #638a9d;
    color: #fff; } }
