/*
index
---------------------------------------- */

body.company {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/company_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.txt_area{
			  background-color: #ebf1f4;
			  padding: 33px 30px;
			  overflow: hidden;
			  margin-bottom: 42px;
			  h4{
			    font-size: 1.57em;
				color: #002b69;
				line-height: 1;
				font-weight: bold;
			  }
			  .color_txt{
			    font-size: 1.14em;
				color: #002b69;
				padding: 18px 0 33px;
				line-height: 1;
			  }
			  img{
				padding-top: 22px;
				float: right;
			  }
			}
	      }
		  .cont02{
			.txt_area{
			  table{
				width: 100%;
				border-collapse: collapse;
				border: 1px solid #d4d4d4;
				tr{
				  border-bottom: 1px solid #d4d4d4;
				  th{
					width: 34.5%;
				    background-color: #f2f2f2;
					padding: 20px 17px;
					color: #002b69;
				  }
				  td{
					padding: 20px 17px;
				  } 
				}
		      }
			}	
		  }
		}
		.inner_02{
		  margin: 115px 0 0 0;
		  .cont01{
		    font-size: 0;
		    .inner_box{
			  vertical-align: top;
		      width: calc(100%/2 - 12px);
			  display: inline-block;
			  margin: 0 24px 45px 0 ;
			  h4{
		        font-size: 20px;
			    color: #002b69;
	      	  }
			  p{
			    font-size: 14px;
				letter-spacing: -0.03em;
			  }
			}
			.inner_box:nth-child(even){
			  margin-right: 0;
			}
			.inner_box:nth-child(3), .inner_box:nth-child(4){
			  margin-bottom: 0;
			}
	      }  
		}
	  }
	  .side-nav{
	    ul{
		  li:nth-child(2){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
