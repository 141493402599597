/*
index
---------------------------------------- */

body.company {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 230px;
		padding-top: 61px;
	    background-image: url(../img/company_main.png);
		background-position:center;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
	      color:#fff;
		  padding: 0 20px 0;
		  position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  margin: auto;
		  h1{
		    font-size: 2.28em;
		  }
		  p{
			font-size: 1.28em;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.txt_area{
			  background-color: #ebf1f4;
			  padding: 16px 15px;
			  overflow: hidden;
			  margin-bottom: 21px;
			  h4{
			    font-size: 1.57em;
				color: #002b69;
				line-height: 1.3;
				font-weight: bold;
			  }
			  .color_txt{
			    font-size: 1.14em;
				color: #002b69;
				padding: 9px 0 16px;
				line-height: 1.3;
			  }
			  img{
				padding-top: 10px;
				float: right;
				width: 70%;
			  }
			}
	      }
		  .cont02{
			.txt_area{
			  table{
				width: 100%;
				border-collapse: collapse;
				border: 1px solid #d4d4d4;
				tr{
				  border-bottom: 1px solid #d4d4d4;
				  th{
					width: 34.5%;
				    background-color: #f2f2f2;
					padding: 10px 9px;
					color: #002b69;
				  }
				  td{
					padding: 10px 9px;
				  } 
				}
		      }
			}	
		  }
		}
		.inner_02{
		  margin: 42px 0 0 0;
		  .cont01{
		    .inner_box{
			  vertical-align: top;
		      width: 100%;
			  display: inline-block;
			  margin: 0 0 23px 0 ;
			  h4{
		        font-size: 1.42em;
			    color: #002b69;
	      	  }
			  p{
				letter-spacing: -0.03em;
			  }
			}
			.inner_box:last-child{
			  margin-bottom: 0;
			}
	      }  
		}
	  }
	}
  }
}
