/*
index
---------------------------------------- */

body.service {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 230px;
		padding-top: 61px;
	    background-image: url(../img/service_main.png);
		background-position:center;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  color:#fff;
		  padding: 0 20px 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  margin: auto;
		  h1{
		    font-size: 2.28em;
		  }
		  p{
			font-size: 1.28em;
		  }
		}
      }
    }
		.wrapper{
				.s1{
					.inner{
						.cont01{
						.b_01{
							background-image: url(../img/service_bg01.png);
							background-position:center;
							background-size: cover;
							background-repeat: no-repeat;
							height: 259px;
							position: relative;
							.txt_area{
							position: absolute;
							top: 50%;
							-webkit-transform: translateY(-50%); /* Safari用 */
							transform: translateY(-50%);
							right: 8%;
							}
						}
						.b_02{
							background-image: url(../img/service_bg02.png);
							background-position:center;
							background-size: cover;
							background-repeat: no-repeat;
							height: 259px;
							position: relative;
							.txt_area{
							position: absolute;
							top: 50%;
							-webkit-transform: translateY(-50%); /* Safari用 */
							transform: translateY(-50%);
							left: 10%;
							}
						}		  
						.inner_cont_box{
							margin-bottom: 13px;
							.txt_area{
								text-align: center;
								color:#fff;
								line-height: 1;
								p{
									font-size: 2.71em;
									padding: 0 10px 15px;
									border-bottom: 1px solid #fff;
								}
								h4{
									padding: 15px 0 0 0 ;
									font-size: 1.14em;
								}
							}
						}
					}
					.cont_inner{
						.cont_inner_area01{
							p:nth-child(1){
								margin: 30px 0;
							}
							.inner_box{
								position: relative;
							.img_area{
								img{
									width: 100%;
								height: 130px;
								}
							}
							.txt_area{
								position: absolute;
								top: 0; bottom: 0; right: 0; left: 0;
								margin: auto;
								height: 90px;
								text-align: center;
								padding: 0 3%;
							h4{
								font-size: 1.2em;
								color:#09469b;
								line-height: 1;
								margin-bottom: 8px;
								font-weight: bold;
							}
							p{
								line-height: 1.2;
							}
						}	
					}
					.inner_box:nth-of-type(1){
						margin-bottom: 13px;
					}
				}
				.cont_inner_area02{
			  	width: 100%;
			  		.inner_box{
			    		width: 100%;
			    		vertical-align: top;
							margin-top: 25px;
							padding-bottom: 30px;
							img{
								width: 100%;
							}
			    		p{
			      		font-size: 12px;
								margin-bottom: 10px;
			    		}
							.subtxt{
								font-size: 10px;
								text-align: right;
								margin-top: 5px;
							}
			  		}			  
		    	}
					.cont_inner_area03 {
						.inner_box{
							.img_area{
								width: 100%;
								img{
									width: 100%;
								}
				 			}
							.txt_area{
								p{
									margin-top: 10px;
									margin-bottom: 0;
								}
							}
						}
					}
	  		}
			}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(1){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
