/*
index
---------------------------------------- */

body.faq {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/faq_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  h2{
		    margin-bottom: 38px;	
		  }
		  .cont01{
		    dl{
			  font-size: 0;
			  margin-bottom: 35px;
			  dt{
			    background-color: #ebf1f4;
				padding: 25px 20px 14px;
				font-size: 18px;
				color: #002b69;
				border-bottom: 1px solid #002b69;
			  }
			  dd{
			    background-color: #fff5f7;
				padding: 14px 20px 25px;
				p{
			      display: inline-block;
				  font-size: 14px;
				  width: 91%;
			  	}
			  }
			  dt::before{
			    content:url(../img/faq_q.png) ;
				vertical-align: middle;
				padding-right: 18px;
			  }
			  dd::before{
			    content:url(../img/faq_a.png) ; 
				padding-right: 12px;
				display: inline-block;
				vertical-align: top;
			  }
			}
			dl:last-child{
			  margin-bottom: none;
			}
	      }
		}
	  }
	  .side-nav{
	    ul{
		  li:nth-child(3){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
