/*
index
---------------------------------------- */

body.privacy {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/company_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .wrap{
			  p{
			    margin-bottom: 20px;
			  }
			  ol{
			    list-style-type: decimal;
				margin: 0 0 20px 20px;
			  }
			  ul{
			    list-style-type: disc;
				margin: 0 0 20px 20px;
			  }
			  h4{
			    color: #002b69;
				font-size: 1.42em;
				padding: 45px 0 15px;
			  }
			  h5{
			    color: #002b69;
				font-size: 1.14em;
				padding: 32px 0 10px;
			  }
			  a{
			    text-decoration: none;
			  }
		  }
		  .cont01{
			h3{
			  margin-top: 0;
			}
		  }
		  .cont02{
			h3{
			  margin-bottom: 0;
			}
			.txt_area{
			  table{
			    border-collapse: collapse;
				border-top: 1px solid #ccc;
				width: 100%;
				tr{
				  border-bottom: 1px solid #ccc;
				  td{
				    width: 25%;
					border-right: 1px solid #ccc;
					padding: 10px;
					font-size: 0.85em;
					vertical-align: top;
				  }
				  td:last-child{
					border-right: 0;  
				  }
				}
			  }
			}
			.txt_area_02{
			  table{
			    border-collapse: collapse;
				border: 1px solid #ccc;
				width: 100%;
				margin-bottom: 20px;
				tr{
				  border-bottom: 1px solid #ccc;
				  th{
					background-color: #f2f2f2;
					width: 50%;
					border-right: 1px solid #ccc;
					padding: 10px;
				  }
				  td{
				    width: 50%;
					border-right: 1px solid #ccc;
					padding: 20px 10px;
					a{
				      color: #638a9d;  
					}
				  }
				  td:last-child, th:last-child{
					border-right: 0;  
				  }
				}
			  }
			}
		  }
		  .cont08{
		    .txt_area{
			  table{
			    border-collapse: collapse;
				border: 1px solid #ccc;
				width: 100%;
				margin-bottom: 20px;
				tr{
				  border-bottom: 1px solid #ccc;
				  th{
					background-color: #f2f2f2;
					border-right: 1px solid #ccc;
					padding: 10px;
				  }
				  td{
					border-right: 1px solid #ccc;
					padding: 20px 10px;
					a{
				      color: #638a9d;  
					}
				  }
				  td:last-child, th:last-child{
					border-right: 0;  
				  }
				}
			  }
			}
		  }
		  .cont09{
		    .txt_area{
			  .last{
			    text-align: right;
				margin-top: 45px;
			  }
			}
		  }
		}
		.inner_02{
		  border-top:1px solid #ccc;
		  margin: 45px 0 0 0 ;
		  padding: 30px 0 0 0 ;
		  .cont01{
			a{
			  text-decoration: none;
			  color: #638a9d;
			  font-size: 1.14em;
			}
			.last{
			  margin-bottom: 30px;  
			}
		  }   
		}
	  }
	}
  }
}
