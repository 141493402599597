/*
index
---------------------------------------- */

body.agency {
  .main {
    .mv {
      position: relative;
      .img-area {
        img {
          width: 100%;
					padding-top: 94px;
        }
      }
      .text-area {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 650px;
        height: 500px;
        text-align: center;
        background: url(../img/top_bg.png);
        background-repeat: no-repeat;
        background-position: center;
        p {
          color: #fff;
        }
				p:nth-of-type(1) {
          margin: 15px 0;
          font-size: 30px;
		  		line-height: 1.2;
        }
        p:nth-of-type(2) {
          margin: 40px 0;
          font-size: 60px;
					font-weight: bold;
					line-height: 1;
					letter-spacing: 0.185em;
        }
        p:nth-of-type(3) {
          font-size: 18px;
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          margin: 30px auto;
          padding: 5px 0;
          width: 60%;
        }
        p:nth-of-type(4) {

          font-size: 16px;
        }
      }
    }
    section {
	  h2 {
      font-family: 'Amiri', serif;
      font-size: 42px;
      color: #b3b3b3;
      letter-spacing: 0.2em;
      }
      h3 {
        color: #638a9d;
        font-size: 20px;
      }
      .area01 {
        position: relative;
        .inner {
          width: $max-width;
          .text-area {
		    		width: $max-width;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 500px;
            margin: auto;
						font-size: 0;
						.inner_box{
							width: 33.33%;
							display: inline-block;
							margin-top: 30px;
							vertical-align: top;
							img{
								max-width: 100%;
								padding: 0 15px;
								margin: auto;
								}
								p{
									font-size: 14px;
									margin: 20px 0 30px;
									padding: 0 15px;
								}
								.inner_box_txt{
									height: 70px;
								}
								a{
									p{
									margin: auto;
								}
							}
						}
          }
        }
      }
      .area02 {
        text-align: center;
        .inline {
          .left-box,
          .right-box {
            width: 100%;
						background-image: url(../img/agency_bg02.png);
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						padding: 100px 0;
            .text-area {
              h3 {
                color: #fff;
                margin-bottom: 25px;
              }
              p {
                color: #fff;
              }
              .btn03 {
                p {
                  margin: 25px auto 0;
                }
              }
            }
          }
          .left-box {
            position: relative;
            .text-area {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              margin: auto;
              height: 250px;
            }
          }
          .right-box {
            //*.text-area {
              //position: absolute;
              //top: 0;
              //bottom: 0;
              //right: 0;
              //left: 0;
              //margin: auto;
              //height: 250px;
            //}
          }
        }
      }
	  .area03{
	    margin:100px auto;
	    text-align: center;
	  	.inner {
          width: $max-width;
		  font-size: 0;
		}
	 	.inner_box{
			  width: calc(100%/3);
			  display: inline-block;
			  margin-top: 30px;
			  img{
			    max-width: 100%;
				margin: auto;
			  }
			  p{
			    font-size: 14px;
				margin: 20px 0 30px;
				padding: 0 15px;
				text-align: left;
			  }
			}

	  }
    }
  }
}
