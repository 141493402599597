/*
 PC common
---------------------------------------- */

.sp-on {
  display: none;
}

.pc-on {
  display: block;
}
body {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: 500;
  color: $color;
  width: 100%;
  margin: 0 auto;
  .inner {
    margin: 0 auto;
  }
  .inline {
    display: flex;
    flex-wrap: wrap;
  }
  header {
    background-color: #fff;
    position: fixed;
    height: 94px;
    width: 100%;
    border-top: 4px solid #b3b3b3;
    z-index: 50;
    .header_cont {
      width: 100%;
    }
    div,
    nav {
      font-size: 0;
      display: inline-block;
    }
    .title_hldr {
      margin-top: 30px;
      margin-left: 30px;
      img {
        width: 260px;
      }
    }
    .main_menu {
      float: right;
      .access {
        font-size: 12px;
        width: 100%;
        position: relative;
        right: 0;
        .tel {
          font-size: 22px;
          vertical-align: middle;
          font-family: Lato;
          font-weight: 400;
          margin-right: 10px;
          img {
            max-width: 100%;
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle;
          }
        }
        p {
          display: inline-block;
        }
      }
      nav {
        width: 100%;
        display: block;
        ul {
          li {
            margin: 10px 0;
            border-left: 1px solid #000;
            a {
              font-size: 14px;
              padding: 10px 30px;
              text-decoration: none;
            }
          }
        }
      }
    }
    .cv_hldr {
      width: 90px;
      height: 90px;
      background-color: #638a9d;
      text-align: center;
      font-size: 13px;
      vertical-align: top;
      float: right;
      a {
        color: #fff;
        text-decoration: none;
        img {
          max-width: 100%;
          margin: 10px auto;
        }
      }
    }
  }
  header:after {
    content: ".";
    line-height: 0;
    display: block;
    overflow: hidden;
    position: absolute;
    top: -4px;
    right: 0;
    width: calc(100%/3);
    border-top: 4px solid #638a9d;
  }
  header:before {
    content: ".";
    line-height: 0;
    display: block;
    overflow: hidden;
    position: absolute;
    top: -4px;
    width: calc(100%/3);
    border-top: 4px solid #09469b;
  }
  .pankuzu{
	padding: 14px 0;
	border-bottom: 1px solid #ccc;
	ul {
      font-size: 0;
	  width: 78.125%;
	  margin: auto;
	  li{
	    display: inline-block;
		font-size: 12px;
	    a{
		  text-decoration: none;
	    }
	  }
	  li::after{
	    content: ">";
		margin: 0 5px;
	  }
	  li:last-child::after{
	    content: none;
	  }
    }
  }
  section {
    .btn01,
    .btn02,
    .btn03 {
      text-decoration: none;
      p {
        width: 260px;
        height: 42px;
        border-radius: 21px;
        background-color: #fff;
        text-align: center;
        font-size: 15px;
        line-height: 38px;
      }
    }
    .btn01 {
      p {
        border: solid 2px #638a9d;
        color: #638a9d;
      }
    }
    .btn02 {
      p {
        border: solid 2px #002b69;
        color: #002b69;
      }
    }
    .btn03 {
      p {
        border: solid 2px #fff;
        color: #fff;
        background-color: inherit;
      }
    }
    .img-area {
      img {
        width: 100%;
      }
    }
  }
  .wrapper{
    width: 78.125%;
	margin: 0 auto 175px;
	overflow: hidden;
	section{
      width: 71.111111%;
      margin-top: 60px;
			float: left;
			h2{
				box-sizing: border-box;
				font-size: 1.71em;
				border-top: 3px solid #ccc;
				border-bottom: 1px solid #ccc;
					padding: 30px 0;
				line-height: 1.2;
				margin-bottom: 24px;
			}
			h3{
				box-sizing: border-box;
				font-size: 1.57em;
				padding: 28px;
				line-height: 1;
				background-color: #f2f2f2;
				margin: 58px 0 30px;
				border-radius: 4px;
			}
    }
  }
  footer {
    border-top: 1px solid #ccc;
    .form-area {
      padding: 35px 0;
      .inner {
        font-size: 0;
        width: $max-width;
        background-color: #fff;
        margin: auto;
        .title_hldr {
          width: 240px;
          padding-right: 30px;
          margin-right: 30px;
          height: 60px;
          display: inline-block;
          border-right: 1px solid #ccc;
          img {
            width: 100%;
            margin-top: 16px;
          }
        }
        .cont_01 {
          width: 850px;
          display: inline-block;
          font-size: 0;
          height: 60px;
          padding-top: 8px;
          vertical-align: top;
          .access {
            display: inline-block;
            text-align: center;
            padding-right: 30px;
            p {
              font-size: 14px;
              line-height: 1.47;
              .color_txt {
                font-size: $fs-17;
                color: #09469b;
              }
            }
          }
          .tel {
            display: inline-block;
            font-size: 44px;
            vertical-align: middle;
            font-family: Lato;
            font-weight: 400;
            color: #002b69;
            padding-bottom: 26px;
            line-height: 0;
            img {
              display: inline-block;
              vertical-align: middle;
              margin: 0 7px 6px 0;
            }
          }
          .btn_form {
            display: inline-block;
            float: right;
            margin-left: 15px;
            a {
              width: 250px;
              height: 42px;
              text-align: center;
              display: block;
              background-color: #09469b;
              color: #fff;
              text-decoration: none;
              border-radius: 21px;
              font-size: 16px;
              line-height: 40px;
            }
          }
        }
      }
    }
    .link_area {
      background-color: #ccc;
      .inner {
        width: $max-width;
        font-size: 0;
        padding: 50px 0 30px;
        p.copy {
          color: #638a9d;
          font-size: 13px;
          text-align: right;
          padding-top: 34px;
        }
        .cont_left {
          display: inline-block;
          width: 35%;
          padding-right: 5%;
          vertical-align: top;
          font-size: 14px;
          p:nth-child(1) {
            font-weight: bold;
          }
          .img_hldr {
            font-size: 0;
            margin-top: 20px;
            img {
              margin-bottom: 8px;
            }
          }
        }
        .cont_right {
          width: 62%;
          display: inline-block;
          ul {
            display: inline-block;
            vertical-align: top;
            margin-right: 60px;
            li a {
              font-size: 13px;
              text-decoration: none;
            }
          }
          ul:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .side-nav{
    width: 25.333333%;
	float: right;
	margin-top: 58px;
	ul{
	  li{
	    border-bottom: 1px solid #fff;
		a{
		  display: block;
		  font-size: 14px;
		  background-color: #f2f2f2;
		  text-decoration: none;
		  padding: 24px 20px;
		  color: #638a9d;
		}
		a::after{
		  content: ">";
		  float: right;
		}
	  }
	  li:last-child{
		border-bottom: none;
	  }
	}
  }
}
