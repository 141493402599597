/*
 SP common
---------------------------------------- */

.sp-on {
  display: block;
}

.pc-on {
  display: none;
}

body {
  font-family: $font-family;
  font-size: 12px;
  line-height: $line-height;
  font-weight: 500;
  color: $color;
  header {
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 50;
    font-size: 0;
    overflow: hidden;
    div,
    nav {
      font-size: 0;
      display: inline-block;
    }
    .title_hldr {
      margin-top: 16px;
      margin-left: 10px;
      width: calc(100% - 116px);
      img {
        width: 175px;
      }
    }
    .main_menu {
      nav {
        width: 100%;
        display: none;
        ul {
          li {
            margin: 10px 0;
            border-left: 1px solid #000;
            a {
              font-size: 14px;
              padding: 10px 20px;
              text-decoration: none;
            }
          }
        }
      }
    }
    .cv_hldr {
      width: 53px;
      height: 53px;
      display: inline-block;
      background-color: #638a9d;
      text-align: center;
      font-size: 9px;
      vertical-align: top;
      letter-spacing: 0.5;
      a {
        color: #fff;
        text-decoration: none;
        img {
          max-width: 45%;
          margin: 5px auto;
        }
      }
    }
    .menubtn_hldr {
      width: 53px;
      height: 53px;
      display: inline-block;
      background-color: #ccc;
      text-align: center;
      font-size: 9px;
      vertical-align: top;
      letter-spacing: 0.5;
      a {
        color: #fff;
        text-decoration: none;
        img {
          max-width: 45%;
          margin: 5px auto;
        }
      }
    }
  }
  .header_cont {
    position: relative;
    z-index: 1;
    width: 100%;
    border-top: 4px solid #b3b3b3;
  }
  .accordion_menu{
    background: #ccc;
    padding: 10px;
    li{
      position: relative;
      background: #fff;
      border-bottom: solid 1px #ccc;
      font-size: 13px;
    }
    li::after{
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      content: "＞";
    }
    a{
      color: #000;
      display: block;
      padding: 10px;
      text-decoration: none;
    }
  }
  header:after {
    content: ".";
    line-height: 0;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100%/3);
    border-top: 4px solid #638a9d;
    z-index: 51;
  }
  header:before {
    content: ".";
    line-height: 0;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: calc(100%/3);
    border-top: 4px solid #09469b;
    z-index: 51;
  }
  .pankuzu{
    padding: 7px 20px;
	border-bottom: 1px solid #ccc;
    ul {
      font-size: 0;
	  li{
	    display: inline-block;
		font-size: 10px;
		 a{
		   text-decoration: none;
	     }
	  }
	  li::after{
	    content: ">";
		margin: 0 5px;
	  }
	  li:last-child::after{
	    content: none;
	  }
    }
  }
  section {
    .btn01,
    .btn02,
    .btn03 {
      text-decoration: none;
      p {
        width: 100%;
        height: 45px;
        border-radius: 30px;
        background-color: #fff;
        text-align: center;
      }
    }
    .btn01 {
      p {
        border: solid 2px #638a9d;
        color: #638a9d;
		padding-top: 11px;
      }
    }
    .btn02 {
      p {
        border: solid 2px #002b69;
        color: #002b69;
		padding-top: 11px;
      }
    }
    .btn03 {
      p {
        border: solid 2px #fff;
        color: #fff;
        background-color: inherit;
		  padding-top: 11px;
      }
    }
    .img-area {
      img {
        width: 100%;
      }
    }
  }
  .wrapper{
    width: 100%;
	margin: 0 auto 87px;
	padding:0 20px; 
	overflow: hidden;
	section{
      margin-top: 30px;
	  h2{
	    box-sizing: border-box;
	    font-size: 1.71em;
	    border-top: 3px solid #ccc;
	    border-bottom: 1px solid #ccc;
        padding: 15px 0;
	    line-height: 1.2;
	    margin-bottom: 12px;
	  }
	  h3{
	    box-sizing: border-box;
	    font-size: 1.57em;
        padding: 14px;
	    line-height: 1;
	    background-color: #f2f2f2;
        margin: 29px 0 20px;
		border-radius: 4px;
	  }
    }
  }
  footer {
    border-top: 1px solid #ccc;
    overflow: hidden;
    .form-area {
      padding: 20px;
      background-color: #fff;
      margin: auto;
      text-align: center;
      font-size: 0.9em;
      .cont_01 {
        overflow: hidden;
        .access {
          p {
            line-height: 1.47;
            .color_txt {
              font-size: 1.2em;
              color: #09469b;
              margin-right: 1em;
            }
          }
        }
        .tel {
          display: inline-block;
          font-size: 2.37em;
          vertical-align: middle;
          font-family: Lato;
          font-weight: 400;
          color: #002b69;
          float: left;
          img {
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            margin: 0 3px 3px 0;
          }
        }
        .btn_form {
          display: inline-block;
          float: right;
          a {
            width: 105px;
            height: 29px;
            text-align: center;
            display: block;
            background-color: #09469b;
            color: #fff;
            text-decoration: none;
            border-radius: 14px;
            padding: 5px 0 0 0;
            margin: 4px 0 0 0;
          }
        }
      }
    }
    .link_area {
      background-color: #ccc;
      padding: 0 20px;
      .inner {
        padding: 20px 0 30px;
        p:last-child {
          color: #638a9d;
          font-size: 0.9em;
          text-align: center;
          padding-top: 16px;
        }
        .cont_left {
          text-align: center;
          padding: 0 0 12px 0;
          font-size: 12px;
          p:nth-child(1) {
            font-weight: bold;
          }
          .img_hldr {
            font-size: 0;
            margin-top: 5px;
            img {
              width: 100px;
              display: inline-block;
              margin-right: 5px;
            }
            img:nth-child(2) {
              margin-right: 0;
            }
          }
        }
        .cont_right {
          ul {
            margin-right: 60px;
            width: 100%;
            border: 1px solid #666;
            li {
              border-bottom: 1px solid #666;
              a {
                text-decoration: none;
                display: block;
                width: 100%;
                color: #666;
                padding: 9px;
              }
            }
            li:last-child {
              border-bottom: 0;
            }
          }
          ul:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
