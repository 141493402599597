/*
index
---------------------------------------- */

body.home {
  .main {
    .mv {
      position: relative;
      .img-area {
				padding-top: 4px;
        #bgvid {
          width: 100%;
					vertical-align: bottom;
        }
				img {
					width: 100%;
				}
      }
      .text-area {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 650px;
        height: 250px;
        text-align: center;
        background: url(../img/top_bg.png);
        background-repeat: no-repeat;
        background-position: center;
        p {
          color: #fff;
        }
        p:nth-of-type(1) {
          margin: 15px 0;
          font-size: 38px;
        }
        p:nth-of-type(2) {
          font-size: 18px;
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          margin: 0 auto;
          padding: 5px 0;
          width: 60%;
        }
        p:nth-of-type(3) {
          margin-top: 30px;
          font-size: 16px;
        }
      }
    }
    section {
	  h2 {
      font-family: 'Amiri', serif;
      font-size: 42px;
      color: #b3b3b3;
      letter-spacing: 0.2em;
      }
      h3 {
        color: #638a9d;
        font-size: 20px;
      }
      .area01 {
        position: relative;
        .inner {
          width: $max-width;
          .text-area {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 350px;
            margin: auto;
            p:nth-child(3) {
              margin: 30px 0;
            }
          }
        }
      }
      .area02 {
        text-align: center;
        margin: 70px 0 ;
        .inner {
          width: $max-width;
          justify-content: space-between;
          margin-top: 50px;
          .left-box,
          .right-box {
            width: calc(95%/2);
            .text-area {
              h4 {
                font-size: 18px;
                color: #002b69;
                margin-bottom: 15px;
              }
              p {
                text-align: left;
                width: 90%;
                margin: 0 auto;
              }
            }
          }
          .left-box {
            position: relative;
            .text-area {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              height: 130px;
            }
          }
          .right-box {
            position: relative;
            .text-area {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              height: 125px;
            }
          }
        }
        .btn02 {
          p {
            margin: 25px auto 0;
          }
        }
      }
      .area03 {
        text-align: center;
        .inline {
          .left-box,
          .right-box {
            width: calc(100%/2);
            .text-area {
              h3 {
                color: #fff;
                margin-bottom: 25px;
              }
              p {
                color: #fff;
              }
              .btn03 {
                p {
                  margin: 25px auto 0;
                }
              }
            }
          }
          .left-box {
            position: relative;
            .text-area {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              margin: auto;
              height: 250px;
            }
          }
          .right-box {
            position: relative;
            .text-area {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              margin: auto;
              height: 250px;
            }
          }
        }
        .box {
          position: relative;
          .text-area {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            height: 425px;
            width: 680px;
            background-color: #fff;
            border-radius: 10px;
            .inline {
              justify-content: space-around;
              width: 80%;
              margin: auto;
            }
            h2 {
              margin-top: 66px;
            }
            p {
              margin: 20px 0;
            }
            p:nth-child(4):before {
              content: "商材一例";
              padding: 5px 10px;
              background-color: #638a9d;
              color: #fff;
              margin-right: 15px;
            }
          }
        }
        .left-box02{
          background: url(../img/index04.png) no-repeat;
          background-size: cover;
          width: 100%;
          padding: 130px 0 140px;
          .text-area {
            h3 {
              color: #fff;
              margin-bottom: 25px;
            }
            p {
              color: #fff;
            }
            .btn03 {
              p {
                margin: 25px auto 0;
              }
            }
          }
        }
      }
    }
  }
}
