/*
index
---------------------------------------- */

body.training {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/training_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.txt_area{
			  background-color: #ebf1f4;
			  padding: 33px;
			  h4{
			    font-size: 1.565em;
				color:#002b69;
				line-height: 1;
				margin-bottom: 27px;
				font-weight: bold;
		      }
			}	
		  }
		  .cont_inner{
			.cont_inner_area01{
			  width: 100%;
			  font-size: 0;
			  .inner_box{
			    width:  calc( 100%/2 - 12px );
			    display: inline-block;
			    vertical-align: top;
			    margin-right: 24px;
				img{
				  width: 100%;
				}
			    h5{
			      font-size: 20px;
				  padding: 20px 0;
		        }
			    p{
			      font-size: 14px;	
			    }
			  }
			  .inner_box:last-child{
			    margin-right: 0;
			  }
		    }
	      }
		}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(4){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
