/*
index
---------------------------------------- */

body.privacy {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 230px;
		padding-top: 61px;
	    background-image: url(../img/company_main.png);
		background-position:center;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
	      color:#fff;
		  padding: 0 20px 0;
		  position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  margin: auto;
		  h1{
		    font-size: 2.28em;
		  }
		  p{
			font-size: 1.28em;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .wrap{
			  ol{
			    list-style-type: decimal;
				margin-left: 15px;
			  }
			  ul{
			    list-style-type: disc;
				margin-left: 15px;
			  }
			  h4{
			    color: #002b69;
				font-size: 1.42em;
				padding: 23px 0 8px;
			  }
			  h5{
			    color: #002b69;
				font-size: 1.14em;
				padding: 16px 0 5px;
			  }
			  a{
			    text-decoration: none;
			  }
		  }
		  .cont01{
			h3{
			  margin-top: 0;
			}
		  }
		  .cont02{
			h3{
			  margin-bottom: 0;
			}
			.txt_area{
			  table{
			    border-collapse: collapse;
				border-top: 1px solid #ccc;
				width: 100%;
				tr{
				  border-bottom: 1px solid #ccc;
				  td{
				    width: 25%;
					border-right: 1px solid #ccc;
					padding: 5px;
					font-size: 0.85em;
				  }
				  td:last-child{
					border-right: 0;  
				  }
				}
			  }
			}
			.txt_area_02{
			  table{
			    border-collapse: collapse;
				border: 1px solid #ccc;
				width: 100%;
				margin-bottom: 10px;
				tr{
				  border-bottom: 1px solid #ccc;
				  th{
					background-color: #f2f2f2;
					width: 50%;
					border-right: 1px solid #ccc;
					padding: 5px;
				  }
				  td{
				    width: 50%;
					border-right: 1px solid #ccc;
					padding: 10px 5px;
					a{
				      color: #638a9d;  
					}
				  }
				  td:last-child, th:last-child{
					border-right: 0;  
				  }
				}
			  }
			}
		  }
		  .cont09{
		    .txt_area{
			  .last{
			    text-align: right;
				margin-top: 23px;
			  }
			}
		  }
		}
		.inner_02{
		  border-top:1px solid #ccc;
		  margin: 23px 0 0 0 ;
		  padding: 15px 0 0 0 ;
		  .cont01{
			a{
			  text-decoration: none;
			  color: #638a9d;
			  font-size: 1.14em;
			}
			.last{
			  margin-bottom: 15px;  
			}
		  }   
		}
	  }
	}
  }
}
