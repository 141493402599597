/*
index
---------------------------------------- */

body.welfare {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/welfare_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.txt_area{
			  h4{
				padding: 38px 0 23px;
			    font-size: 1.42em;
				color:#002b69;
				line-height: 1;
				font-weight: bold;
		      }
			  table{
				width: 100%;
				border-collapse: collapse;
				border: 1px solid #d4d4d4;
				tr{
				  border-bottom: 1px solid #d4d4d4;
				  th{
					width: 34.5%;
				    background-color: #f2f2f2;
					padding: 20px 17px;
					color: #002b69;
				  }
				  td{
					padding: 20px 17px;
				  } 
				}
		      }
			}	
		  }
		}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(4){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
