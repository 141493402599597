/*
index
---------------------------------------- */

body.recruit {
  .main {
    .mv {
      .title-area {
        width: 100%;
        height: 344px;
        padding-top: 94px;
         background-image: url(../img/welfare_main.png);
        background-position:top;
        background-size: cover;
        background-repeat: no-repeat;
        .main_tit{
          width: 80%;
          color:#fff;
          margin: auto;
          overflow: hiddeancn;
          font-size: 0;
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%); /* Safari用 */
          transform: translateY(-50%);
          h1{
            font-size: 32px;
            display: inline-block;
            line-height: 1;
          }
          p{
            display: inline-block;
            font-size: 18px;
            margin-left: 27px;
            line-height: 1;
            position: absolute;
            top:8px;
          }
        }
      }
    }
	.wrapper{
	  .s1{
      .inner{
        .anchor{
          padding: 10px 20px;
          background: #ebf1f4;
          border: solid 1px #a3a3a4;
          margin-bottom: 40px;
          li{
            display: inline-block;
            margin-right: 20px;
          }
          a{
            text-decoration: none;
          }
        }
        .cont01,.cont02{
          .txt_area{
            h4{
            padding: 38px 0 23px;
              font-size: 1.42em;
            color:#002b69;
            line-height: 1;
              }
            table{
              width: 100%;
              border-collapse: collapse;
              border: 1px solid #d4d4d4;
            tr{
              border-bottom: 1px solid #d4d4d4;
              th{
                width: 34.5%;
                background-color: #f2f2f2;
                padding: 20px 17px;
                color: #002b69;
              }
              td{
                padding: 20px 17px;
              } 
            }
              }
          }	
        }
        .cont01{
          margin-bottom: 100px;
        }
        .page_btn{
          width: 215px;
          margin: 0 auto;
          margin-top: 40px;
          margin-bottom: 70px;
          background: #f2f2f2;
          border: solid 1px #a3a3a4;
          text-align: center;
          a{
            display: block;
            padding: 10px 20px;
            text-decoration: none;
          }
        }
        .last{
          margin-bottom: 0;
        }
      }	
      #recruit01,#recruit02,#recruit02-1,#recruit02-2,#recruit02-3{
        margin-top: -94px;
        padding-top: 94px;
      }
	  }
	  .side-nav{
	    ul{
		  li:nth-child(4){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
