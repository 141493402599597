/*
index
---------------------------------------- */

body.contact {
  .main {
    .mv {
      .title-area {
        width: 100%;
        height: 230px;
        padding-top: 61px;
        background-image: url(../img/contact_main.png);
        background-position:center;
        background-size: cover;
        background-repeat: no-repeat;
        .main_tit{
		  color:#fff;
		  padding: 0 20px 0;
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%); /* Safari用 */
          transform: translateY(-50%);
		  margin: auto;
		  h1{
		    font-size: 2.28em;
		  }
		  p{
			font-size: 1.28em;
		  }
        }
      }
    }
	.wrapper{
	  .s1{
	    float: none;
		.tab_area{
		  width: 100%;
		  background-color: #ebf1f4;
		  padding: 18px 20px;
		  margin: 18px 0 43px;
	      border: 1px #a3a3a4 solid;
		  input[type="radio"]{
			margin-right: 10px;	
		  }
		  label{
			display: inline-block;
			width: 100%;
		  }
		}
		.contents_area{
        	.contents_area_inner{
		  .hully{
		    padding: 0 0 20px 0 ;
			a{
			  text-decoration: none;
			  color: #638a9d;
			}
		  }

	      table {
			width: 100%;
			border: 1px #ccc solid;
			th{
				border-bottom: 1px #ccc solid;
				padding: 20px;
			    background: #f2f2f2;
				width: 30%;
				color: #002b69;
			}
			td {
				width: 70%;
				padding: 12px;
				border-bottom: 1px #ccc solid;
				border-right: 1px #ccc solid;
			}
			.radio_btn {
				display: inline-block;
				margin-right: 5px;
				label{
				  padding-left: 0;
				}
			}
			input[type="text"] {
				width: 70%;
				padding: 6px 8px;
				border: 1px #ccc solid;
				background: #fff;
			}
			label {
				display: inline-block;
				padding: 8px;
			}
			p { 
				padding: 4px 0;
			}
			input[type="text"].mail {
				width: 90%;
			}
			input[type="text"].year {
				width: 70px;
				margin: 0 10px 0 0;
			}
			input[type="text"].birthday {
				width: 40px;
				margin: 0 10px;
			}
			select, textarea {
				padding: 12px;
				border: 1px #ccc solid;
				background: #fff;
			}
			textarea {
				width: 100%;
				height: 240px;
			}
		  }
        	}
			.hide{
		  display: none;
		}
		}
		.btn_area{
		  text-align: center;
		  margin-bottom: 50px;
		  p{
			padding: 35px 0 23px;	
		  }
		  a{
			color: #638a9d;
		  }
		  .submit-hldr{
			.submit{
			  width: 26.875%;
			  padding: 8px 15px;
			  border: 1px #a3a3a4 solid;
			  background-color: #f2f2f2;
			  cursor: pointer;
		    }	
		  }
		}
		.area01{
	  		h5{
			  font-size: 16px;
			  margin-bottom: 15px;
			}
	  		p{
			  margin: 15px 0;
				span{
				  font-weight: bold;
				}
		  }
	  }
		#tell1, #tell2, #tell3{
			margin-top:-57px;
			padding-top:57px;
		}
	  }
	  .side-nav{
	    ul{
		  li:nth-child(5){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
