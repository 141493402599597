/*
index
---------------------------------------- */

body.business {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/business_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.b_01{
			  background-image: url(../img/business_bg01.png);
			  background-position:center;
			  background-size: cover;
			  background-repeat: no-repeat;
			  height: 259px;
			  position: relative;
			  .txt_area{
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%); /* Safari用 */
				transform: translateY(-50%);
				right: 8%;
			  }
			}
			.b_02{
			  background-image: url(../img/business_bg02.png);
			  background-position:center;
			  background-size: cover;
			  background-repeat: no-repeat;
			  height: 259px;
			  position: relative;
			  .txt_area{
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%); /* Safari用 */
				transform: translateY(-50%);
				left: 10%;
			  }
			}
			.inner_cont_box:nth-of-type(3){
			  background-image: url(../img/business_bg03.png);
			  margin-bottom: 0;
			}
			.inner_cont_box{
			  margin-bottom: 13px;
			  .txt_area{
				text-align: center;
				color:#fff;
				line-height: 1;
				p{
				  font-size: 2.71em;
				  padding: 0 10px 15px;
				  border-bottom: 1px solid #fff;
				}
				h4{
				  padding: 15px 0 0 0 ;
				  font-size: 1.14em;
				}
			  }
			}
			
		  }
		  .cont_inner{
			background-image: url(../img/business_bg04.png);
			background-position:center;
			background-size: cover;
			background-repeat: no-repeat;
			height: 483px;
			overflow: hidden;
			margin: 73px 0 0 0 ;
			.cont_inner_txt{
			  width: 61%;
			  float: right;
			  padding: 57px 0 0 0;
			  h5{
			    color: #002b69;
				font-size: 1.42em;
				margin-bottom: 32px;
			  }
			　p{
			    line-height: 1.85;
			  }
			}
	      }
		}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(2){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
