/*
index
---------------------------------------- */

body.support {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/support_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01, .cont02{
		  margin-bottom: 100px;
		  .cont_inner{
			.cont_inner_area01, .cont_inner_area02{
			  width: 100%;
			  font-size: 0;
			  h4{
			    font-family: 'Amiri', serif;
			    font-size: 2rem;
			    color: #b3b3b3;
			    line-height: 1;
				margin-bottom: 10px;
			  }
			  h4:after{
			    content:  counter(support) ;
				font-size: 3rem;
			  }
			  .inner_box{
			    width:  calc( 100%/2 - 12px );
			    display: inline-block;
			    vertical-align: top;
			    margin-right: 24px;
				margin-top: 25px;
				counter-increment: support;
				img{
				  width: 100%;
				}
			    h5{
			      font-size: 20px;
				  padding: 20px 0 15px;
				  line-height: 1.2;
				  font-weight: bold;
		        }
			    p{
			      font-size: 14px;	
			    }
				h6{
				  color: #002b69;
				  font-size: 14px;
				  font-weight: bold;
				  text-align: right;
				  span{
				    font-size: 20px;
					margin-left: 10px;
				  }
				}
			  }
			  .inner_box:nth-child(2n){
			    margin-right: 0;
			  }
			  .inner_box:nth-child(n+3){
			    margin-top: 50px;
			  }
		    }
			.cont_inner_area02{
				.inner_box{
					img{
						width: auto;
						max-width: 100%;
						margin: 0 auto;
					}
					.txt-area{
					border-radius: 10px;
					border: solid 1px #cbcbcb;
					padding: 0 20px 20px;
					}
				}
			}
			
	      }
		  }
		  .cont02{
		    margin-top: 100px;
		  }
		  .cont03{
		    margin-top: 100px;
			.cont_inner{
			  ul{
				width: 100%;
				font-size: 0;
				li{
				  display: inline-block;
				  width: calc(100% / 3);
				  vertical-align: middle;
				  margin: 10px 0;
				  img{
				    max-width: 100%;
				    margin: 0 auto;
				  }
				}
			  }
			  p{
			    text-align: right;
			  }
			}
		  }
		  #support{
		  margin-top:-60px;
			padding-top: 60px;
		  //padding-top:94px;
		  }
		  #massage, #commodity{
		  margin-top:-94px;
		  padding-top:94px;
		  }		  
		}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(3){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
