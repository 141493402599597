/*
index
---------------------------------------- */

body.agency {
  .main {
    .mv {
      position: relative;
      .img-area {
        img {
          width: 100%;
        }
      }
      .text-area {
	    	position: absolute;
				margin: 0 auto;
				top: 25%; bottom: 0; right: 0; left: 0;
				width: 80%;
				height: 300px;
        text-align: center;
        background: url(../img/top_bg.png);
        background-repeat: no-repeat;
        background-position: center;
        p {
          color: #fff;
        }
				p:nth-of-type(1) {
        	margin: 15px 0;
        	font-size: 1.7em;
		 			line-height: 1.2;
        }
        p:nth-of-type(2) {
					margin: 15px 0;
					font-size: 3.4em;
					font-weight: bold;
					line-height: 1;
					letter-spacing: 0.185em;
        }
        p:nth-of-type(3) {
					font-size: $fs-18;
					border-top: 1px solid #fff;
					border-bottom: 1px solid #fff;
					margin: 0 auto;
					padding: 5px 0;
					width: 93%;
        }
        p:nth-of-type(4) {
		  margin-top: 15px;
		  line-height: 1.63;
		  font-size: 1em;
        }
      }
    }
		section{
			h2 {
					font-family: 'Amiri', serif;
					font-size: 30px;
					color: #b3b3b3;
					line-height: 1;
					margin-bottom: 10px;
				}
				h3 {
					color: #638a9d;
					font-size: 20px;
					line-height: 1;
					margin-bottom: 15px;
				}
				.area01 {
					padding: 50px 0;
					text-align: center;
					background: url(../img/agency_bg01_sp.png);
					background-size: 100% auto;
					.inner {
						.text-area {
						margin: auto;
						font-size: 0;
						padding: 0 20px;
						.inner_box{
							width: 100%;
							display: inline-block;
							margin-top: 30px;
							img{
								width: 100%;
								margin: auto;
							}
							p{
								text-align: left;
								font-size: 14px;
								margin: 20px 0 30px;
							}
							a{
								p{
								margin: auto;
								text-align: center;
								width: 90%;
								}
							}
							.btn01{
								p{
									border: solid 2px #638a9d;
									color: #638a9d;
									padding-top: 8px!important;
								}
							}
						}
					}
				}
			}
			.area02{
				text-align: center;
			.inline{
				.left-box, .right-box{
					width: 100%;
				.text-area{
				padding: 49px 0;
					h3{
						 color:#fff;
					}
					p{
						color:#fff;
					width: 64%;
					margin: auto;
					}
					.btn03{
						p{
							margin: 14px auto 0;
						width: 87.5%;
					}
					} 
				}
				}
					.left-box{
					.text-area{
					background-image: url(../img/agency_bg02.png);
					background-position: right;
					background-size: cover;
				}
				}
				.right-box{
					.text-area{
					background-image: url(../img/agency_bg03.png);
					background-position: right;
					background-size: cover;
				}
				}

			}
			}
			.area03{
				margin:50px auto 100px;
				text-align: center;
			padding:  0 20px;
				.inner {
				font-size: 0;
			}
			.inner_box{
					width: 100%;
					display: inline-block;
					margin-top: 30px;
					img{
						width: 100%;
					margin: auto;
					}
					p{
						font-size: 14px;
					margin: 20px 0 0;
					text-align: left;
					}
			}
			}
    }
  }
}
