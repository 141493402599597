/*
index
---------------------------------------- */

body.home {
  .main{
    .mv{
	  position: relative;
		overflow: hidden;
	  .img-area{
			height: 584px;
	    #bgvid {
				padding-top: 57px;
				background-attachment: fixed;
				background-position: center center;
				background-size: cover;
				left: 0;
      }
	    img{
	      width: 100%;
		  	padding-top: 57px;
	    }
	  }
	  .text-area{
	    position: absolute;
			margin: 0 auto;
			top: 35%; bottom: 0; right: 0; left: 0;
			width: 80%;
			text-align: center;
			background: url(../img/top_bg_sp.png);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: top;
	    p{
		  color: #fff; 
			}
			p:nth-of-type(1){
				margin: 15px 0;
				font-size: 2.7em;
				line-height: 1.29;
			}
			p:nth-of-type(2){
				font-size: $fs-20;
					border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
				margin: 0 auto;
				padding: 5px 0;
				width: 93%;
				}
			p:nth-of-type(3){
				margin-top: 15px;
				line-height: 1.63;
				font-size: 1.1em;
			}
	  }
	}
	section{
	  h2 {
        font-family: 'Amiri', serif;
        font-size: 2.5em;
        color: #b3b3b3;
        line-height: 1;
        margin-bottom: 10px;
      }
      h3 {
        color: #638a9d;
        font-size: 20px;
        line-height: 1;
        margin-bottom: 15px;
      }
	  .area01{
	    position: relative;
		.img-area{
		    width: 100%;
			img{
			  width: 100%;
			}
	      }
		.inner{
		  width: 100%;
		  .text-area{
			padding: 0 20px;
		    position: absolute;
		    top:0;
			bottom: 0;
			height: 350px;
			margin: auto;
			p:nth-child(3){
			  margin: 30px 0;
			}
			p{
			  line-height: 1.6;
			  margin: 0 0 20px 0;
			}
		  }
		}
	  }
	  .area02{
		margin: 50px 20px;
		  p{
			  margin-top: 15px;
		  }
		.inner{
		  margin-top: 20px;
		  .left-box{
			margin-bottom: 10px;	
	      }
		  .left-box, .right-box{
		    width:100%;
			.text-area{
				padding: 25px 10px;
			  h4{
			    font-size: 1.2em;
			    color:#002b69;
				text-align: center;
			  }
			  p{
			    margin: 0 auto;
				line-height: 1.6;
			  }
			}
		  }
		  .left-box{
		    position: relative;
			.text-area{
			  position: absolute;
			  top: 0;
			  bottom: 0;
			  margin: auto;
			}
		  }
		  .right-box{
		    position: relative;
			.text-area{
			  position: absolute;
			  top: 0;
			  bottom: 0;
			  margin: auto;
			}
		  }

		  }
		.btn02{
		  p{
		    margin: 15px auto 0;
		  }
		}
	  }
	  .area03{
	    text-align: center;
		.inline{
		  .left-box, .right-box{
		    width: 100%;
			.text-area{
			padding: 49px 0;
			  h3{
  			   color:#fff;
			  }
			  p{
			    color:#fff;
				width: 64%;
				margin: auto;
			  }
			  .btn03{
			    p{
			      margin: 14px auto 0;
				  width: 87.5%;
				}
			  } 
			}
		  }
	      .left-box{
		    .text-area{
			  background-image: url(../img/index04.png);
			  background-position: right;
			  background-size: cover;
			}
		  }
		  .right-box{
		    .text-area{
			  background-image: url(../img/index05.png);
			  background-position: right;
			  background-size: cover;
			}
		  }
		  
		}
		.box{
		  background-image: url(../img/index06.png);
	      background-position: center;
		  background-size: cover;
		  padding: 20px 0;
		  .text-area{
			  margin: auto;
			  width: 93.75%;
			  margin: auto;
			  padding: 35px 10px 20px;
			  background-color: #fff;
			  h3{
				  font-size: 1.2em;
				  line-height: 1.45;
			  }
			  .inline{
			    justify-content: space-around;
			    width: 80%;
			    margin: auto;
				.btn01{
				  p{
				    margin-bottom: 5px;
				  }
				}
			  }
			  p:nth-child(3){
				  margin-bottom: 44px;
			  }
			  p:nth-child(4){
				  margin-bottom: 20px;
				  position: relative;
			  }
			  p:nth-child(4):before{
				  content: "商材一例";
				  padding: 4px 9px;
				  background-color: #638a9d;
				  color: #fff;
				  text-align: center;
				  position: absolute;
				  top:-28px;
				  left: 40%;
			 }
		  }
		}
	  }
	}
  }
}
