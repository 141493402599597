@charset "UTF-8";

@import "component/config";
@import "component/mixin";

@import "component/reset";
@import "component/base";

@media (min-width: 768px) {
	@import "parts/pc/pc-common";
	@import "parts/pc/pc-index";
	@import "parts/pc/pc-training";
	@import "parts/pc/pc-welfare";
	@import "parts/pc/pc-business";
	@import "parts/pc/pc-company";
	@import "parts/pc/pc-service";
	@import "parts/pc/pc-support";
  	@import "parts/pc/pc-recruit";
	@import "parts/pc/pc-privacy";
	@import "parts/pc/pc-faq";
	@import "parts/pc/pc-flow";
	@import "parts/pc/pc-agency";
	@import "parts/pc/pc-contact";
}

@media (max-width: 767px) {
	@import "parts/sp/sp-common";
	@import "parts/sp/sp-index";
	@import "parts/sp/sp-training";
	@import "parts/sp/sp-welfare";
	@import "parts/sp/sp-business";
  	@import "parts/sp/sp-company";
	@import "parts/sp/sp-service";
	@import "parts/sp/sp-support";
  	@import "parts/sp/sp-recruit";
  	@import "parts/sp/sp-privacy";
	@import "parts/sp/sp-faq";
	@import "parts/sp/sp-flow";
	@import "parts/sp/sp-agency";
	@import "parts/sp/sp-contact";
}