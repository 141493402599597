/*
index
---------------------------------------- */

body.business {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 230px;
		padding-top: 61px;
	    background-image: url(../img/business_main.png);
		background-position:center;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
	      color:#fff;
		  padding: 0 20px 0;
		  position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  margin: auto;
		  h1{
		    font-size: 2.28em;
		  }
		  p{
			font-size: 1.28em;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.b_01{
			  background-image: url(../img/business_bg01.png);
			  background-position:center;
			  background-size: cover;
			  background-repeat: no-repeat;
			  height: 172px;
			  position: relative;
			  .txt_area{
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%); /* Safari用 */
				transform: translateY(-50%);
				right: 8%;
			  }
			}
			.b_02{
			  background-image: url(../img/business_bg02.png);
			  background-position:center;
			  background-size: cover;
			  background-repeat: no-repeat;
			  height: 172px;
			  position: relative;
			  .txt_area{
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%); /* Safari用 */
				transform: translateY(-50%);
				left: 10%;
			  }
			}
			.inner_cont_box:nth-of-type(3){
			  background-image: url(../img/business_bg03.png);
			  margin-bottom: 0;
			}
			.inner_cont_box{
			  margin-bottom: 7px;
			  .txt_area{
				text-align: center;
				color:#fff;
				line-height: 1;
				p{
				  font-size: 2.71em;
				  padding: 0 5px 8px;
				  border-bottom: 1px solid #fff;
				}
				h4{
				  padding: 8px 0 0 0 ;
				  font-size: 1.14em;
				}
			  }
			}
			
		  }
		  .cont_inner{
			margin: 37px 0 0 0 ;
			.cont_inner_txt{
			  width: 100%;
			  padding: 28px 0 0 0;
			  h5{
			    color: #002b69;
				font-size: 1.42em;
				margin-bottom: 16px;
			  }
			　p{
			    line-height: 1.85;
			  }
			}
	      }
		}	
	  }
	}
  }
}
