/*
index
---------------------------------------- */

body.flow {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/flow_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
		  .cont_inner{
			.cont_inner_area01{
			  width: 100%;
			  font-size: 0;
			  h4{
			    font-size: 1.2rem;
			    color: #b3b3b3;
			    line-height: 1.2;
				margin:0 auto 10px;
				text-align: center;
				vertical-align: middle;
				border: 2px solid #cbcbcb;
				border-radius: 50%;
				width: 110px;
				height: 110px;
				background-color: #fff;
				padding-top: 20px;
			  }
			  h4:after{
			    content:'0' counter(support);
				color: #002b69;
				font-size: 2.4rem;
				font-weight: bold;
			  }
			  .inner_box{
			    width: 100%;
				margin-top: 25px;
				counter-increment: support;
				position: relative;
				margin-bottom: 80px;
				vertical-align: center;
				.txt-area{
				  padding:0 20px;
				  text-align: center;
				}
				.txt-area:after{
				  display: block;
				  content: "";
				  clear: both;
				}
				.img-area{
				  max-width:180px;
				  margin: 10px auto;
				  img{
			      max-width: 100%;
				  }
				}

			    h5{
			      font-size: 20px;
				  padding: 0 0 20px;
				  line-height: 1;
				  font-weight: bold;
		        }
			    p{
			      font-size: 14px;	
			    }
			  }
			  .inner_box:nth-child(2n-1){
			    background-color:#ebf1f4;
			    padding:20px;
			  }
			  .inner_box:nth-child(2n){
			    background-color:#f2f2f2;
			     padding:20px;
			  }
			  .inner_box::before{
					content: "";
					position: absolute;
					top: 100%;
					left: 50%;
 					margin-left: -50px;
					border: 50px solid transparent;
					border-top: 50px solid #e0edff;
			  }
			  .inner_box:nth-child(2n-1):before{
			  	border-top-color: #ebf1f4;
			  }
			  .inner_box:nth-child(2n):before{
			    border-top-color: #f2f2f2;
			  }
			  .inner_box:last-child:before{
			    display: none;
			  }
			  
		    }
			
	      }
		  }
		}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(3){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
