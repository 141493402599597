/*
index
---------------------------------------- */

body.service {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 344px;
		padding-top: 94px;
	    background-image: url(../img/service_main.png);
		background-position:top;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
		  width: 80%;
	      color:#fff;
		  margin: auto;
		  overflow: hidden;
		  font-size: 0;
	      position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  h1{
		    font-size: 32px;
			display: inline-block;
			line-height: 1;
		  }
		  p{
		    display: inline-block;
			font-size: 18px;
			margin-left: 27px;
			line-height: 1;
			position: absolute;
			top:8px;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.b_01{
			  background-image: url(../img/service_bg01.png);
			  background-position:center;
			  background-size: cover;
			  background-repeat: no-repeat;
			  height: 259px;
			  position: relative;
			  .txt_area{
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%); /* Safari用 */
				transform: translateY(-50%);
				right: 8%;
			  }
			}
			.b_02{
			  background-image: url(../img/service_bg02.png);
			  background-position:center;
			  background-size: cover;
			  background-repeat: no-repeat;
			  height: 259px;
			  position: relative;
			  .txt_area{
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%); /* Safari用 */
				transform: translateY(-50%);
				left: 10%;
			  }
			}		  
			.inner_cont_box{
			  margin-bottom: 13px;
			  .txt_area{
				text-align: center;
				color:#fff;
				line-height: 1;
				p{
				  font-size: 2.71em;
				  padding: 0 10px 15px;
				  border-bottom: 1px solid #fff;
				}
				h4{
				  padding: 15px 0 0 0 ;
				  font-size: 1.14em;
				}
			  }
			}
		  }
		  .cont_inner{
		  	.cont_inner_area01{
			  p:nth-child(1){
			    margin: 30px 0;
			  }
			  .inner_box{
			    position: relative;
				.img_area{
				  img{
				  	width: 100%;
				  }
				}
			    .txt_area{
				  position: absolute;
				  top: 0; bottom: 0; right: 0; left: 0;
                  margin: auto;
                  height: 100px;
				  text-align: center;
				  width: 650px;
			      h4{
			        font-size: 1.565em;
				    color:#09469b;
				    line-height: 1;
				    margin-bottom: 20px;
					font-weight: bold;
		          }
				}	
			  }
			  .inner_box:nth-of-type(1){
			    margin-bottom: 13px;
			  }
			}
			.cont_inner_area02{
			  width: 100%;
			  .inner_box{
			    width: 100%;
			    vertical-align: top;
			    margin-right: 24px;
					img{
						width: 100%;
					}
			    p{
						margin-bottom: 25px;
			    }
					.subtxt{
						font-size: 13px;
						margin-top: 10px;
						text-align: right;
					}
			  }
		 	}
	  }
		  
		.cont_inner{
			.cont_inner_area03{
			  .inner_box{
				  margin-top: 30px;
						.img_area{
						width: 48%;
						margin-right: 2%;
						float: left;
						img{
							width: 100%;
						}
				  }
				  .txt_area{
				    width: 48%;
					margin-left: 2%;
					float: left;
					h5{
					    font-size: 20px;
						padding: 10px 0;
						font-weight: bold;
					}
				  }
				}
				.inner_box:after{
				  content: "";
				  display: block;
				  clear: both;
				}
			  }
			}
		}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(1){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
