/*
index
---------------------------------------- */

body.welfare {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 230px;
		padding-top: 61px;
	    background-image: url(../img/training_main.png);
		background-position:center;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
	      color:#fff;
		  padding: 0 20px 0;
		  position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  margin: auto;
		  h1{
		    font-size: 2.28em;
		  }
		  p{
			font-size: 1.28em;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.txt_area{
			  h4{
				padding: 19px 0 12px;
			    font-size: 1.42em;
				color:#002b69;
				line-height: 1;
				font-weight: bold;
		      }
			  table{
				width: 100%;
				border-collapse: collapse;
				border: 1px solid #d4d4d4;
				tr{
				  border-bottom: 1px solid #d4d4d4;
				  th{
					width: 34.5%;
				    background-color: #f2f2f2;
					padding: 10px 8px;
					color: #002b69;
				  }
				  td{
					padding: 10px 8px;
				  } 
				}
		      }
			}	
		  }
		}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(4){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
