/*
index
---------------------------------------- */

body.training {
  .main {
    .mv {
      .title-area {
		width: 100%;
		height: 230px;
		padding-top: 61px;
	    background-image: url(../img/training_main.png);
		background-position:center;
		background-size: cover;
		background-repeat: no-repeat;
		.main_tit{
	      color:#fff;
		  padding: 0 20px 0;
		  position: relative;
		  top: 50%;
		  -webkit-transform: translateY(-50%); /* Safari用 */
		  transform: translateY(-50%);
		  margin: auto;
		  h1{
		    font-size: 2.28em;
		  }
		  p{
			font-size: 1.28em;
		  }
		}
      }
    }
	.wrapper{
	  .s1{
		.inner{
		  .cont01{
			.txt_area{
			  background-color: #ebf1f4;
			  padding: 17px;
			  h4{
			    font-size: 1.565em;
				color:#002b69;
				line-height: 1.18;
				margin-bottom: 14px;
				font-weight: bold;
		      }
			}	
		  }
		  .cont_inner{
			.cont_inner_area01{
			  width: 100%;
			  .inner_box{
				margin-bottom: 20px;
				img{
			      width: 100%;
		        }
			    h5{
				  padding:10px 0;
			      font-size: 1.42em;
		        }
			  }
			  .inner_box:last-child{
			    margin-bottom: 0;
			  }
		    }
	      }
		}	
	  }
	  .side-nav{
	    ul{
		  li:nth-child(4){
			a{
			  background-color: #638a9d;
			  color: #fff;
			}
		  }
		}
	  }
	}
  }
}
